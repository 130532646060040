import {
    Button,
    Card,
    CircularProgress,
    Grid,
    makeStyles,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CustomContext from '../context/CustomContext';
import { useSnackbar } from "notistack";
import { saveAs } from 'file-saver';
import { properties } from "../configs/properties";

const useStyle = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(8),
    },
    fullWidth: {
        width: "100%",
    },
    downloadPdf: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
}));

const ExportProductionCode = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [deviceID, setDeviceID] = useState('');
    const [deviceIDError, setDeviceIDError] = useState(false);
    const [topic, setTopic] = useState('');
    const [topicError, setTopicError] = useState(false);

    const handleChangeDeviceID = (e) => {
        setDeviceIDError(false);
        setDeviceID(e.target.value);
    }

    const handleChangeTopic = (e) => {
        setTopicError(false);
        setTopic(e.target.value);
    }

    const validation = () => {
        let result = true;

        if (!code || code?.length === 0) {
            result = false;
            setCodeError(true);
        }

        if (!topic || topic?.length === 0) {
            result = false;
            setTopicError(true);
        }

        if (!deviceID || deviceID?.length === 0) {
            result = false;
            setDeviceIDError(true);
        }

        return result;
    }

    const handleClickSave = () => {
        if (validation()) {
            setLoading(true);
            ctx.exportProductionCodeXls({
                productionCode: code,
                topic: topic,
                serialNumber: deviceID
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes) {
                        let blobArr = [["codice-produzione", "seriale", "topic", "timestamp", "payload\n"]];
                        jsonRes.forEach(item => {
                            blobArr.push([
                                item.productionCode,
                                item.serialNumber,
                                item.topic,
                                item.timestamp,
                                item.payload + "\n"
                            ])
                        });
                        saveAs(new Blob(blobArr), `export-${new Date()}.csv`);
                        setLoading(false);
                        enqueueSnackbar("Export avvenuto con successo", { variant: 'success' });
                        resetForm();
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    }
                })
        }
    }

    const resetForm = () => {
        setCode('');
        setTopic('');
        setDeviceID('');
    }

    const handleChangeCode = (e) => {
        setCodeError(false);
        setCode(e.target.value);
    }

    useEffect(() => {
        /* initEnums(); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <form className={classes.form} noValidate>
                <Typography variant="h4" color='textSecondary'>{"Export Codice Produzione - turni"}</Typography>
                <Grid
                    container
                    spacing={3}
                    justify="space-around"
                    className={classes.container}
                >
                    <Grid item lg={12} className={classes.fullWidth}>
                        <Card raised className={classes.card}>
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="code"
                                        label="Codice Produzione"
                                        name="code"
                                        value={code}
                                        onChange={handleChangeCode}
                                        error={codeError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="deviceId"
                                        label="Seriale"
                                        name="deviceId"
                                        value={deviceID}
                                        onChange={handleChangeDeviceID}
                                        error={deviceIDError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="topic"
                                        label="Topic"
                                        name="topic"
                                        value={topic}
                                        onChange={handleChangeTopic}
                                        error={topicError}
                                    />
                                </Grid>

                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Button
                    onClick={handleClickSave}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    style={{ marginTop: theme.spacing(2) }}
                >
                    {isLoading ? (
                        <CircularProgress size="2em" color='primary' />
                    ) : (
                        <span style={{ fontWeight: "bold" }}>{"Export"}</span>
                    )}
                </Button>
            </form>
        </div>
    );
}

export default ExportProductionCode;