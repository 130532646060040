import React, { useState } from 'react';
import AppRouter from './configs/AppRouter';
import { Button, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { FaTimes } from 'react-icons/fa';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1d294b'
    },
    secondary: {
      main: '#a1c52b'
    }
  }
})

function App() {

  const [stateTheme, setStateTheme] = useState(theme);

  const handleChangeTheme = (theme) => {
    setStateTheme(createMuiTheme(theme));
  }

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          <FaTimes color='white' />
        </Button>
      )}
      hideIconVariant
    >
      {
        // remove className App
      }
      <div className="App">
        <ThemeProvider theme={stateTheme}>
          <AppRouter handleChangeTheme={handleChangeTheme} />
        </ThemeProvider>
      </div>
    </SnackbarProvider>
  );
}

export default App;
