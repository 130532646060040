import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { CircularProgress, makeStyles, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';


const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const UserList = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [userList, setUserList] = useState([]);
    const [count, setCount] = useState(0);


    const getTableTitle = () => {
        return 'Lista manutentori';
    }

    const getData = async () => {
        setLoading(true);
        let filters = {
            limit: limit,
            offset: offset
        }
        ctx.superadminList(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setUserList(jsonRes.users);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    const columns = [
        {
            name: "nome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Nome"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "cognome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Cognome"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "username",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Email"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "numClienti",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Clienti"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "id",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"User"}</div>,
            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender: (value, tableData, updatedValue) => {

                    return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {value}
                    </div>
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    return (
        <div>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{getTableTitle()}</Typography>
                {
                    isLoading &&
                    <div className={classes.overlay}>
                        <CircularProgress color={"primary"} />
                    </div>
                }

                <MUIDataTable
                    data={userList}
                    columns={columns}
                    options={options}
                />


            </div>
        </div>

    )
}

export default UserList;