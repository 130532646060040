import { createContext } from 'react';
import { properties } from '../configs/properties';
import {
    superadminRoutes,
    adminRoutes,
    userRoutes,
    demoRoutes, completeProfileRoutes, devRoutes
} from '../configs/routes';
import Cookies from 'js-cookie';

var logo = null;

const CustomContext = createContext({
    login: async (username, password) => {
        return await fetch(`${properties.API_URL}/auth/login`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            }
        ).catch(e => { console.error(e) })
    },
    logout: () => {
        Cookies.remove(properties.tokenKey);
        window.location = '/login';
    },
    getUserCookie: () => {
        return Cookies.getJSON('refineair-user');
    },
    setCookieUserCompleted: () => {
        let user = Cookies.getJSON('refineair-user');
        user.resetPw = false;
        Cookies.set('refineair-user', user);
        window.location = '/';
    },
    getRoutesByRole: () => {
        var user = Cookies.getJSON('refineair-user');
        if (user) {
            if (user.resetPw === true) {
                return completeProfileRoutes;
            } else {
                switch (user.accountTipologiaDomain.nome) {
                    case 'superadmin': {
                        return superadminRoutes;
                    }
                    case 'admin': {
                        return adminRoutes;
                    }
                    case 'user': {
                        return userRoutes;
                    }
                    case 'demo': {
                        return demoRoutes;
                    }
                    case 'dev': {
                        return devRoutes;
                    }
                    default: {
                        return userRoutes;
                    }
                }
            }
        } else {
            return [];
        }
    },
    recoverPassword: async (username) => {
        return await fetch(`${properties.API_URL}/user/recoverPassword/${username}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json'
                }
            }
        ).catch(e => { console.error(e) })
    },
    getCreateReportEnums: async () => {
        return await fetch(`${properties.API_URL}/enum/createReport`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getCreateDeviceEnums: async () => {
        return await fetch(`${properties.API_URL}/enum/createDevice`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getDeviceListEnums: async () => {
        return await fetch(`${properties.API_URL}/enum/deviceList`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getDeviceReadListEnums: async () => {
        return await fetch(`${properties.API_URL}/enum/deviceReadList`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getAdminDeviceListEnums: async () => {
        return await fetch(`${properties.API_URL}/enum/adminDeviceList`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    uploadLogoAzienda: async (formData) => {
        return fetch(
            `${properties.API_URL}/user/upload`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: formData
            }
        ).catch(e => { console.error(e) })
    },
    uploadProductionCodeCsv: async (formData) => {
        return fetch(
            `${properties.API_URL}/turniProduzione/upload`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: formData
            }
        ).catch(e => { console.error(e) })
    },
    getAdminSettingsEnum: async () => {
        return fetch(
            `${properties.API_URL}/enum/adminSettings`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    saveAdminSettings: async (body) => {
        return fetch(
            `${properties.API_URL}/user/saveAdminSettings`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(body)
            }
        ).catch(e => { console.error(e) })
    },
    saveNewReport: async (report, file) => {
        const formData = new FormData();
            formData.append(
                'file',
                file,
                file.name
            );
            formData.append('report', JSON.stringify(report));
        return fetch(
            `${properties.API_URL}/report/insert`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: formData
            }
        ).catch(e => { console.error(e) })
    },
    getUserLogged: async () => {
        return fetch(
            `${properties.API_URL}/user/findByToken`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getReportList: async (filters) => {
        return fetch(
            `${properties.API_URL}/report/list`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    getOriginalReportFile: async (idReport) => {
        return fetch(
            `${properties.API_URL}/report/pdf/original/${idReport}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getReportFile: async (idReport) => {
        return fetch(
            `${properties.API_URL}/report/pdf/${idReport}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getTipoAmbienteAnalytics: async () => {
        return fetch(
            `${properties.API_URL}/tipoAmbiente/getAnalytics`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getInstallerDashboardAnalytics: async () => {
        return fetch(
            `${properties.API_URL}/dashboard/installerAnalytics`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getAdminDashboardAnalytics: async () => {
        return fetch(
            `${properties.API_URL}/dashboard/adminAnalytics`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getUserDashboardAnalytics: async () => {
        return fetch(
            `${properties.API_URL}/dashboard/userAnalytics`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getSuperadminSettingsEnums: async () => {
        return fetch(
            `${properties.API_URL}/enum/superadminSettings`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    insertInstaller: async (installer) => {
        return fetch(
            `${properties.API_URL}/user/generateInstaller`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(installer)
            }
        ).catch(e => { console.error(e) })
    },
    emailExists: async (email) => {
        return fetch(
            `${properties.API_URL}/user/emailExists/${email}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    generateUsers: async (body) => {
        return fetch(
            `${properties.API_URL}/user/generateUsers`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(body)
            }
        ).catch(e => { console.error(e) })
    },
    completeProfile: async (completeProfileRequest) => {
        return fetch(
            `${properties.API_URL}/user/completeProfile`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(completeProfileRequest)
            }
        ).catch(e => { console.error(e) })
    },
    readLog: async () => {
        return fetch(
            `${properties.API_URL}/log/read`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProductionCodeByFilters: async(filters) => {
        return fetch(
            `${properties.API_URL}/turniProduzione/findByFilters`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    superadminList: async (filters) => {
        return fetch(
            `${properties.API_URL}/user/superadminList`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    superadminDimensions: async (filters) => {
        return fetch(
            `${properties.API_URL}/user/superadminDimensions`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    accountTopicList: async (filters) => {
        return fetch(
            `${properties.API_URL}/accountTopic/findByFilters`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    topicList: async (filters) => {
        return fetch(
            `${properties.API_URL}/topic/findByFilters`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    getTopicListEnum: async () => {
        return fetch(
            `${properties.API_URL}/topic/enum`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    updateAccountTopicList: async (request) => {
        return fetch(
            `${properties.API_URL}/accountTopic/update`,
            {
                method: 'PATCH',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(request)
            }
        ).catch(e => { console.error(e) })
    },
    topicExistsByName: async (nome) => {
        return fetch(
            `${properties.API_URL}/topic/existsByName/${nome}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    updateUserDimension: async (user) => {
        return fetch(
            `${properties.API_URL}/user/updateDimensions`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(user)
            }
        ).catch(e => { console.error(e) })
    },
    updateTopic: async (topic) => {
        return fetch(
            `${properties.API_URL}/topic/update`,
            {
                method: 'PATCH',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(topic)
            }
        ).catch(e => { console.error(e) })
    },
    insertTopic: async (topic) => {
        return fetch(
            `${properties.API_URL}/topic/insert`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(topic)
            }
        ).catch(e => { console.error(e) })
    },
    saveTipoAmbiente: async (tipoAmbiente) => {
        return fetch(
            `${properties.API_URL}/tipoAmbiente/add`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(tipoAmbiente)
            }
        ).catch(e => { console.error(e) })
    },
    savePuntoCampionamento: async (puntoCampionamento) => {
        return fetch(
            `${properties.API_URL}/puntoCampionamento/add`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(puntoCampionamento)
            }
        ).catch(e => { console.error(e) })
    },
    getReportListEnums: async () => {
        return fetch(
            `${properties.API_URL}/enum/reportList`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProgressEnums: async () => {
        return fetch(
            `${properties.API_URL}/enum/progress`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProgressData: async (filters) => {
        return fetch(
            `${properties.API_URL}/report/progress`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    tipoAmbienteExists: async (nome) => {
        return fetch(
            `${properties.API_URL}/tipoAmbiente/exists/${nome}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    puntoCampionamentoExists: async (nome) => {
        return fetch(
            `${properties.API_URL}/puntoCampionamento/exists/${nome}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProgressDataFile: async (filters) => {
        return fetch(
            `${properties.API_URL}/report/pdf/progress`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    saveNewDevice: async (device) => {
        return fetch(
            `${properties.API_URL}/accountDispositivo/create`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(device)
            }
        ).catch(e => { console.error(e) })
    },
    saveNewProductionCode: async (prodCode) => {
        return fetch(
            `${properties.API_URL}/turniProduzione/create`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(prodCode)
            }
        ).catch(e => { console.error(e) })
    },
    getInstallerDeviceList: async (filters) => {
        return fetch(
            `${properties.API_URL}/accountDispositivo/installerListByFilters`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    getAdminDeviceList: async (filters) => {
        return fetch(
            `${properties.API_URL}/accountDispositivo/adminListByFilters`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    getDeviceReadList: async (filters) => {
        return fetch(
            `${properties.API_URL}/letturaDispositivo/listByFilters`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    getDeviceTopicResponse: async (topic, serialNumber, daysFrom, pageSize, page, order) => {
        return fetch(
            `https://mqtt.refineair.it/api/mqtt/GetDevicesDataHistoryPaged?topic=${topic}&daysFrom=${daysFrom}&serialNumber=${serialNumber}&page=${page}&pageSize=${pageSize}&order=${order}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).catch(e => { console.error(e) })
    },
    readNotification: async (topic, deviceId) => {
        return fetch(
            `${properties.API_URL}/letturaDispositivo/insert`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify({ topic: topic, idAccountDispositivo: deviceId })
            }
        ).catch(e => { console.error(e) })
    },
    updateDevice: async (device) => {
        return fetch(
            `${properties.API_URL}/accountDispositivo/update`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(device)
            }
        ).catch(e => { console.error(e) })
    },
    getUserLogo: async (id) => {
        return fetch(
            `${properties.API_URL}/user/findLogoByIdInstallatore/${id}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    exportProductionCodeXls: async (body) => {
        return fetch(
            `${properties.API_URL}/turniProduzione/export`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(body)
            }
        ).catch(e => { console.error(e) })
    },
    findLogo: async () => {
        return fetch(
            `${properties.API_URL}/user/findLogo`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    setLogo: (blob) => {
        logo = blob;
    },
    getLogo: () => {
        return logo;
    }
})

export default CustomContext;