import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";

const defaultFooterStyles = {
};

class CustomFooter extends React.Component {

    handlePageChange = (_, page) => {
        this.props.changePage(this.props.page > page ? 'back' : 'next', page);
    };

    render() {
        const { count, rowsPerPage, page, nextDisabled } = this.props;
        const rowsTo = (page + 1) * rowsPerPage;

        const footerStyle = {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px 24px 0px 24px'
        };

        return (
            <TableFooter>
                <TableRow>
                    <TableCell style={footerStyle} colSpan={1000}>

                        <MuiTablePagination
                            component="div"
                            count={count === 0 ? 0 : (rowsTo + 8)}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"Righe per pagina:"}
                            labelDisplayedRows={({ from, to, count }) => from !== 0 ? `${from}-${rowsTo}` : ''}
                            backIconButtonProps={{
                                'aria-label': 'Indietro'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Avanti',
                                disabled: nextDisabled
                            }}
                            onChangePage={this.handlePageChange}
                            rowsPerPageOptions={[]}
                        />
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
    }

}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(CustomFooter);