import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Avatar, Button, Card, CardHeader, CircularProgress, Collapse, Grid, makeStyles, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import { FaChevronDown, FaChevronUp, FaUserCircle } from 'react-icons/fa';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';


const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const DeviceReadList = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [deviceList, setDeviceList] = useState([]);
    const [count, setCount] = useState(0);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [reset, setReset] = useState(false);
    //////////////////////////////////////////
    const [nome, setNome] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [installers, setInstallers] = useState([]);
    const [selectedInstaller, setSelectedInstaller] = useState(null);

    const handleChangeNome = (e) => {
        setNome(e.target.value);
    }

    const handleChangeSerialNumber = (e) => {
        setSerialNumber(e.target.value);
    }

    const handleChangeInstallatore = (e, newValue) => {
        setSelectedInstaller(newValue);
    }

    const handleClickResetFilter = () => {
        setSerialNumber('');
        setSelectedInstaller(null);
        setNome('');
        setReset(true);
    }

    const handleClickFilter = () => {
        getData();
    }

    const getData = async () => {
        setLoading(true);
        let filters = {
            serialNumber: serialNumber,
            idUtenteInstallatore: selectedInstaller?.id,
            nome: nome,
            limit: limit,
            offset: offset
        }
        ctx.getDeviceReadList(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setDeviceList(jsonRes.deviceList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const initEnums = () => {
        setLoading(true);
        ctx.getDeviceReadListEnums()
            .then(res => {
                if (res?.status === 200) {
                    return res.json();
                } else if (res?.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    // error
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setInstallers(jsonRes.installatori);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        initEnums();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        getData()
            .then(() => {
                setReset(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    const columns = [
        {
            name: "nome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Nome"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 15) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 12) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "topic",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Topic"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "serialNumber",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Seriale"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "utenteInstallatoreDomain",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Manutentore"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome + ' ' + value?.cognome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "timestamp",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Timestamp"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    return <div className={classes.centered} >{moment(new Date(value)).format('DD/MM/YYYY - HH:mm')}</div>;
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{'Lista di letture dispositivi'}</Typography>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>

                            <Grid container style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant='h6' color='textSecondary'>
                                                    {'Filtri'}
                                                </Typography>
                                                {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                    : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                            </div>}
                                            onClick={() => { setFiltersVisible(!filtersVisible) }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Collapse in={filtersVisible}>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="serialNumber"
                                                        label="Seriale"
                                                        name="serialNumber"
                                                        value={serialNumber}
                                                        onChange={handleChangeSerialNumber}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="nome"
                                                        label="Nome"
                                                        name="nome"
                                                        value={nome}
                                                        onChange={handleChangeNome}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <Autocomplete
                                                        id="installatori"
                                                        options={installers}
                                                        value={selectedInstaller}
                                                        onChange={handleChangeInstallatore}
                                                        getOptionLabel={(option) => (option.nome + ' ' + option.cognome)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Manutentore"
                                                                variant="outlined"
                                                                fullWidth
                                                                margin='normal'
                                                            />
                                                        }
                                                        renderOption={(option) =>
                                                            <React.Fragment>
                                                                <Avatar style={{ marginRight: '10px' }}>
                                                                    <FaUserCircle />
                                                                </Avatar>
                                                                {option.nome + ' ' + option.cognome}
                                                            </React.Fragment>
                                                        }
                                                        ListboxProps={{
                                                            style: {
                                                                border: '1px solid rgba(0,0,0,0.2)',
                                                                borderRadius: '3px'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    md={12}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={handleClickResetFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={handleClickFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: theme.spacing(1) }}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            </Grid>
                            <MUIDataTable
                                data={deviceList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                }

            </div>
        </MuiPickersUtilsProvider>

    )
}

export default DeviceReadList;