import { Avatar, Button, Card, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { properties } from '../configs/properties';
import { useSnackbar } from 'notistack';
import CustomContext from '../context/CustomContext';
import { Autocomplete } from '@material-ui/lab';
import { FaPlusCircle, FaUser, FaUserCircle, FaUserFriends, FaUsers } from 'react-icons/fa';
import Transition from '../components/Transition';
import { useHistory } from 'react-router';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    innerContainer: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2)
    },
    fullWidth: {
        width: "100%",
    },
    submit: {
        marginTop: theme.spacing(4),
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
    logoAzienda: {
        // height: '100%',
        // width: '100%'
    }
}))

const SuperadminSettings = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [isLoading, setLoading] = useState(false);
    const [installatori, setInstallatori] = useState([]);
    const [selectedInstallatore, setSelectedInstallatore] = useState(null);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [azienda, setAzienda] = useState('');
    const [nomeError, setNomeError] = useState(false);
    const [cognomeError, setCognomeError] = useState(false);
    const [aziendaError, setAziendaError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [confirmGenUsersDialogOpen, setConfirmGenUsersDialogOpen] = useState(false);
    const [pacchetti, setPacchetti] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({})


    const handleCreateUsers = () => {
        setLoading(true);
        ctx.generateUsers({ idUtenteInstallatore: selectedInstallatore.id, idPacchettoUtente: selectedPackage?.id })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setConfirmGenUsersDialogOpen(false);
                    setLoading(false);
                    enqueueSnackbar('Utenze create correttamente.', { variant: 'success' })
                    setSelectedPackage({});
                    setSelectedInstallatore(null);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const handleCloseGenUsersDialog = () => {
        setConfirmGenUsersDialogOpen(false);
    }

    const handleChangeInstallatore = (e, newValue) => {
        setSelectedInstallatore(newValue);
    }

    const handleChangeNome = (e) => {
        setNomeError(false);
        setNome(e.target.value);
    }

    const handleChangeCognome = (e) => {
        setCognomeError(false);
        setCognome(e.target.value);
    }

    const handleChangeEmail = (e) => {
        let emailValid = validateEmail(e.target.value);
        setEmailError(!emailValid);
        setEmail(e.target.value);
    }

    const handleChangeAzienda = (e) => {
        setAziendaError(false);
        setAzienda(e.target.value);
    }

    const handleClickGenUsersPack = (index) => {
        setSelectedPackage(pacchetti[index]);
        setConfirmGenUsersDialogOpen(true);
    }

    const validation = () => {
        let result = true;
        if (nome?.length === 0) {
            setNomeError(true);
            result = false;
        }

        if (cognome?.length === 0) {
            setCognomeError(true);
            result = false;
        }

        let emailValid = validateEmail(email);
        if (!emailValid) {
            setEmailError(true);
            result = false;
        }

        if (azienda?.length === 0) {
            setAziendaError(true);
            result = false;
        }

        return result;
    }

    const getInstallerModel = () => {
        return {
            nome: nome,
            cognome: cognome,
            email: email,
            azienda: azienda
        }
    }

    const resetInstallerForm = () => {
        setNome('');
        setCognome('');
        setEmail('');
        setAzienda('');
    }

    const validateEmail = (m) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(m)) {
            return true;
        }
        return false;
    }

    const handleSaveInstaller = () => {
        if (validation()) {
            setLoading(true);

            ctx.emailExists(email)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(exists => {
                    if (exists !== null && exists === true) {
                        setLoading(false);
                        enqueueSnackbar('Email già in uso.', { variant: 'warning' });
                    } else if (exists !== null && exists === false) {
                        let installer = getInstallerModel();
                        ctx.insertInstaller(installer)
                            .then(res => {
                                if (res.status === 200) {
                                    return res.json();
                                } else if (res.status === 401) {
                                    setLoading(false);
                                    ctx.logout();
                                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                                } else {
                                    setLoading(false);
                                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                    return null;
                                }
                            })
                            .then(jsonRes => {
                                if (jsonRes) {
                                    getEnums();
                                    setLoading(false);
                                    resetInstallerForm();
                                    enqueueSnackbar(properties.successMessage, { variant: 'success' })
                                }
                            })
                            .catch(e => {
                                setLoading(false);
                                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                            })
                    }
                })
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })


        } else {
            enqueueSnackbar('Compila correttamente i campi.', { variant: 'warning' })
        }
    }

    const getEnums = () => {
        setLoading(true);
        ctx.getSuperadminSettingsEnums()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setInstallatori(jsonRes.installatori);
                    setPacchetti(jsonRes.pacchetti);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getEnums();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.container}>
            <Typography variant="h4" color='textSecondary'>{"Impostazioni amministratore"}</Typography>
            <Grid
                container
                spacing={3}
                justify="space-around"
                className={classes.innerContainer}
            >
                <Grid item lg={12} className={classes.fullWidth}>
                    <Card raised className={classes.card}>
                        <CardHeader
                            title="Link"
                            titleTypographyProps={{ variant: "h6" }}
                        />
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(2),
                                    paddingRight: theme.spacing(2),
                                    paddingBottom: theme.spacing(2),
                                }}
                            >
                                <Button
                                    onClick={() => {history.push('/topicList')}}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoading}
                                    style={{
                                        marginRight: theme.spacing(2),
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {'Topic'}
                                </Button>
                                <Button
                                    onClick={() => {history.push('/accountTopicList')}}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={isLoading}
                                    style={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {'Account ⇿ Topic'}
                                </Button>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>
                <Grid item lg={12} className={classes.fullWidth}>
                    <Card raised className={classes.card}>
                        <CardHeader
                            title="Creazione utenze clienti"
                            titleTypographyProps={{ variant: "h6" }}
                        />
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(2),
                                    paddingRight: theme.spacing(2),
                                }}
                            >
                                <Autocomplete
                                    id="installatori"
                                    options={installatori}
                                    value={selectedInstallatore}
                                    onChange={handleChangeInstallatore}
                                    getOptionLabel={(option) => (option.nome + ' ' + option.cognome)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Manutentore"
                                            variant="outlined"
                                            fullWidth
                                            margin='normal'
                                        // error={clienteError}
                                        />
                                    }
                                    renderOption={(option) =>
                                        <React.Fragment>
                                            <Avatar style={{ marginRight: '10px' }}>
                                                <FaUserCircle />
                                            </Avatar>
                                            {option.nome + ' ' + option.cognome}
                                        </React.Fragment>
                                    }
                                    ListboxProps={{
                                        style: {
                                            border: '1px solid rgba(0,0,0,0.2)',
                                            borderRadius: '3px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                container
                                xs={12}
                                className={classes.fullWidth}
                                style={{
                                    paddingTop: theme.spacing(2),
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                {
                                    pacchetti?.map((p, index) => {
                                        let width = pacchetti?.length <= 4 ? 12 / pacchetti?.length : 3;
                                        return <Grid
                                            key={index}
                                            item
                                            sm={width}
                                            xs={12}
                                            style={{
                                                padding: theme.spacing(3),
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            {index === 0 && <FaUser size={50} color={theme.palette.primary.main} />}
                                            {index === 1 && <FaUserFriends size={50} color={theme.palette.primary.main} />}
                                            {index > 1 && <FaUsers size={50} color={theme.palette.primary.main} />}
                                            <Typography variant='h6' color='textSecondary' style={{ marginTop: theme.spacing(2) }} >
                                                {`${p.valore} Clienti`}
                                            </Typography>
                                            <Button
                                                onClick={() => { handleClickGenUsersPack(index) }}
                                                fullWidth
                                                variant="contained"
                                                style={{
                                                    marginTop: theme.spacing(3),
                                                    height: '40px',
                                                    backgroundColor: selectedInstallatore === null ? 'lightgray' : theme.palette.secondary.main
                                                }}
                                                disabled={selectedInstallatore === null}
                                            >
                                                <FaPlusCircle color='white' size={20} />
                                            </Button>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item lg={12} className={classes.fullWidth}>
                    <Card raised className={classes.card}>
                        <CardHeader
                            title="Creazione utenza manutentore"
                            titleTypographyProps={{ variant: "h6" }}
                        />
                        <Grid container>
                            <Grid
                                item
                                container
                                xs={12}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='nome'
                                        label='Nome'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={nome}
                                        onChange={handleChangeNome}
                                        error={nomeError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='cognome'
                                        label='Cognome'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={cognome}
                                        onChange={handleChangeCognome}
                                        error={cognomeError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='azienda'
                                        label='Azienda'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={azienda}
                                        onChange={handleChangeAzienda}
                                        error={aziendaError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='email'
                                        label='Email'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={email}
                                        onChange={handleChangeEmail}
                                        error={emailError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                        paddingBottom: theme.spacing(1),
                                        justifyContent: 'flex-end',
                                        display: 'flex'
                                    }}
                                >
                                    <Button
                                        onClick={handleSaveInstaller}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size="2em" color='primary' />
                                        ) : (
                                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

            </Grid>

            {/* <Button
                onClick={() => { }}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <CircularProgress size="2em" color='primary' />
                ) : (
                        <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                    )}
            </Button> */}

            <Dialog
                open={confirmGenUsersDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseGenUsersDialog}
                aria-labelledby="gen-users-dialog"
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>{'Conferma'}</DialogTitle>
                <DialogContent>
                    <Typography variant='h6' color='textSecondary'>{`Creare ${selectedPackage?.valore} utenze per ${selectedInstallatore?.nome} ${selectedInstallatore?.cognome}?`}</Typography>
                    <Button
                        onClick={handleCloseGenUsersDialog}
                        variant='contained'
                        disabled={isLoading}
                        style={{ float: 'left', marginTop: theme.spacing(3) }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{'Annulla'}</span>
                    </Button>
                    <Button
                        onClick={handleCreateUsers}
                        variant='contained'
                        color='primary'
                        disabled={isLoading}
                        style={{ float: 'right', marginTop: theme.spacing(3) }}
                    >
                        {isLoading ? (
                            <CircularProgress size="2em" color='primary' />
                        ) : (
                            <span style={{ fontWeight: "bold" }}>{"Si"}</span>
                        )}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SuperadminSettings;