import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CustomContext from '../context/CustomContext';
import { useSnackbar } from "notistack";
import { properties } from "../configs/properties";
import { FaPlus, FaPlusCircle, FaTrash } from "react-icons/fa";
import Transition from "../components/Transition";
import { Divider } from "@material-ui/core";
import { ListItem } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(8),
    },
    fullWidth: {
        width: "100%",
    },
    downloadPdf: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
}));

const CreateDevice = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();
    const [serialNumber, setSerialNumber] = useState('');
    const [serialNumberError, setSerialNumberError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [nomeError, setNomeError] = useState(false);
    const [topic, setTopic] = useState('');
    const [topicError, setTopicError] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    const [deviceIdError, setDeviceIdError] = useState(false);
    const [tipoAmbienteEnum, setTipoAmbienteEnum] = useState([]);
    const [topicEnum, setTopicEnum] = useState([]);
    const [tipoAmbiente, setTipoAmbiente] = useState('');
    const [tipoAmbienteError, setTipoAmbienteError] = useState(false);
    const [tipoProdotto, setTipoProdotto] = useState('');
    const [tipoProdottoError, setTipoProdottoError] = useState(false);
    const [addTipoAmbienteDialogOpen, setAddTipoAmbienteDialogOpen] = useState(false);
    const [tipoAmbienteAdd, setTipoAmbienteAdd] = useState('');
    const [tipoAmbienteAddError, setTipoAmbienteAddError] = useState(false);
    const [topicList, setTopicList] = useState([]);
    const [clientiEnum, setClientiEnum] = useState([]);
    const [cliente, setCliente] = useState('');
    const [clienteError, setClienteError] = useState(false);
    const [tipoDeviceEnum, setTipoDeviceEnum] = useState([]);

    const handleClickAddTopic = () => {
        if (!topic || topic?.length === 0) {
            setTopicError(true);
        } else {
            if (topicList.filter(t => t.id === topic).length === 0) {
                setTopicList([topicEnum.filter(t => t.id === topic)[0], ...topicList]);
            }
            setTopic('');
        }
    }

    const handleRemoveTopic = (topic) => {
        setTopicList(topicList.filter(t => t.id !== topic.id));
    }

    const handleChangeTipoAmbienteAdd = (e) => {
        setTipoAmbienteAddError(false);
        setTipoAmbienteAdd(e.target.value);
    }

    const getDeviceModel = () => {
        return {
            nome: nome,
            topic: topicList,
            idTipoAmbiente: tipoAmbiente,
            serialNumber: serialNumber,
            deviceId: deviceId,
            tipoProdotto: tipoProdotto,
            idCliente: cliente
        }
    }

    const validation = () => {
        let result = true;

        if (!nome || nome?.length === 0) {
            result = false;
            setNomeError(true);
        }

        if (topicList?.length === 0) {
            result = false;
            setTopicError(true);
        }

        if (!serialNumber || serialNumber?.length === 0) {
            result = false;
            setSerialNumberError(true);
        }

        if (!deviceId || deviceId?.length === 0) {
            result = false;
            setDeviceIdError(true);
        }

        if(!cliente || cliente?.length === 0) {
            result = false;
            setClienteError(true);
        }

        if(!tipoProdotto || tipoProdotto?.length === 0) {
            result = false;
            setTipoProdottoError(true);
        }

        return result;
    }

    const handleClickSave = () => {
        if (validation()) {
            setLoading(true);
            ctx.saveNewDevice(getDeviceModel())
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes === true) {
                        setLoading(false);
                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                        resetForm();
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    }
                })
        }
    }

    const resetForm = () => {
        setNome('');
        setTopic('');
        setTipoAmbiente('');
        setSerialNumber('');
        setTopicList([]);
        setDeviceId('');
        setCliente('');
        setTipoProdotto('');
    }


    const handleClickSaveAddTipoAmbiente = () => {
        if (!tipoAmbienteAdd || tipoAmbienteAdd?.length === 0) {
            setTipoAmbienteAddError(true);
        } else {
            setLoading(true);
            ctx.tipoAmbienteExists(tipoAmbienteAdd)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes != null) {
                        if (jsonRes === false) {
                            ctx.saveTipoAmbiente({ nome: tipoAmbienteAdd, cancellato: false })
                                .then(res => {
                                    if (res.status === 200) {
                                        return res.json();
                                    } else if (res.status === 401) {
                                        setLoading(false);
                                        ctx.logout();
                                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                                    } else {
                                        setLoading(false);
                                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                        return null;
                                    }
                                })
                                .then(jsonRes => {
                                    if (jsonRes) {
                                        setLoading(false);
                                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                                        handleCloseAddTipoAmbienteDialog();
                                        initEnums();
                                    }
                                })
                        } else {
                            setLoading(false);
                            enqueueSnackbar('Tipo ambiente già esistente.', { variant: 'warning' });
                        }

                    }
                })

        }
    }

    const handleCloseAddTipoAmbienteDialog = () => {
        setAddTipoAmbienteDialogOpen(false);
        setTipoAmbienteAdd('');
        setTipoAmbienteAddError(false);
    }

    const handleChangeTipoProdotto = (e) => {
        setTipoProdottoError(false);
        setTipoProdotto(e.target.value);
    }

    const handleChangeTipoAmbiente = (e) => {
        if (e.target.value !== -1) {
            setTipoAmbienteError(false);
            setTipoAmbiente(e.target.value);
        } else {
            setTipoAmbiente('');
            setTipoAmbienteError(false);
            setAddTipoAmbienteDialogOpen(true);
        }
    }

    const handleChangeCliente = (e) => {
        setClienteError(false);
        setCliente(e.target.value);
    }

    const handleChangeTopic = (e) => {
        setTopicError(false);
        setTopic(e.target.value);
    }

    const handleChangeDeviceId = (e) => {
        setDeviceIdError(false);
        setDeviceId(e.target.value);
    }

    const handleChangeNome = (e) => {
        setNomeError(false);
        setNome(e.target.value);
    }

    const handleChangeSerialNumber = (e) => {
        setSerialNumberError(false);
        setSerialNumber(e.target.value);
    }


    const initEnums = () => {
        setLoading(true);
        ctx.getCreateDeviceEnums()
            .then(res => {
                if (res?.status === 200) {
                    return res.json();
                } else if (res?.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    // error
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setTipoAmbienteEnum(jsonRes.tipoAmbienteDomainList);
                    setTopicEnum(jsonRes.topicDomainList);
                    setClientiEnum(jsonRes.clienti);
                    setTipoDeviceEnum(jsonRes.tipoDeviceDomainList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        initEnums();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <form className={classes.form} noValidate>
            <Typography variant="h4" color='textSecondary'>{"Creazione Device"}</Typography>
            <Grid
                container
                spacing={3}
                justify="space-around"
                className={classes.container}
            >
                <Grid item lg={12} className={classes.fullWidth}>
                    <Card raised className={classes.card}>
                        <Grid container>
                            <Grid
                                item
                                lg={6}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="nome"
                                    label="Nome"
                                    name="nome"
                                    value={nome}
                                    onChange={handleChangeNome}
                                    error={nomeError}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="serialNumber"
                                    label="Seriale"
                                    name="serialNumber"
                                    value={serialNumber}
                                    onChange={handleChangeSerialNumber}
                                    error={serialNumberError}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="deviceId"
                                    label="Device ID"
                                    name="deviceId"
                                    value={deviceId}
                                    onChange={handleChangeDeviceId}
                                    error={deviceIdError}
                                />
                            </Grid>

                            <Grid
                                item
                                lg={6}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <TextField
                                    id="tipo_ambiente"
                                    select
                                    label="Tipo di ambiente"
                                    value={tipoAmbiente}
                                    onChange={handleChangeTipoAmbiente}
                                    error={tipoAmbienteError}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    <MenuItem value={-1}>
                                        <FaPlusCircle style={{ marginRight: theme.spacing(1) }} />
                                        <em>{'Aggiungi'}</em>
                                    </MenuItem>
                                    {tipoAmbienteEnum.map((ta) => {
                                        return (
                                            <MenuItem key={ta.id} value={ta.id}>
                                                {ta.nome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <TextField
                                    id="tipo_prodotto"
                                    select
                                    label="Tipologia prodotto"
                                    value={tipoProdotto}
                                    error={tipoProdottoError}
                                    onChange={handleChangeTipoProdotto}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    {tipoDeviceEnum.map((c) => {
                                        return (
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.nome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <TextField
                                    id="clienti"
                                    select
                                    label="Cliente"
                                    value={cliente}
                                    onChange={handleChangeCliente}
                                    error={clienteError}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    {clientiEnum.map((c) => {
                                        return (
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.nome + ' ' + c.cognome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                lm={5}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <TextField
                                    id="topic"
                                    select
                                    label="Topic"
                                    value={topic}
                                    error={topicError}
                                    onChange={handleChangeTopic}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    <MenuItem value=''>
                                        <em>
                                            {'Selezionare topic'}
                                        </em>
                                    </MenuItem>
                                    {topicEnum.map((ta) => {
                                        return (
                                            <MenuItem key={ta.id} value={ta.id}>
                                                {ta.nome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                lm={1}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    display: 'flex'
                                }}
                            >
                                <Button
                                    onClick={handleClickAddTopic}
                                    style={{height: '70%', width: '100%', marginBottom: '9px'}}
                                    variant="contained"
                                    color="primary"
                                >
                                    <FaPlus color='white' />
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            topicList?.map((item, index) => {
                                return <ListItem button key={index}>
                                    <Grid container >
                                        <Grid
                                            item
                                            xs={8}
                                            className={classes.fullWidth}
                                            style={{
                                                padding: theme.spacing(2)
                                            }}
                                        >
                                            {item.nome}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            className={classes.fullWidth}
                                            style={{
                                                paddingLeft: theme.spacing(3),
                                                paddingRight: theme.spacing(3),
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}
                                        >
                                            <Button
                                                onClick={() => handleRemoveTopic(item)}
                                                fullWidth
                                                variant="text"
                                            >
                                                <FaTrash color='red' />
                                            </Button>
                                        </Grid>
                                        <Divider style={{ width: '100%', backgroundColor: 'gray' }} />
                                    </Grid>
                                </ListItem>
                            })
                        }
                    </Card>
                </Grid>
            </Grid>

            <Button
                onClick={handleClickSave}
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
                style={{ marginTop: theme.spacing(2) }}
            >
                {isLoading ? (
                    <CircularProgress size="2em" color='primary' />
                ) : (
                    <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                )}
            </Button>
            <Dialog
                open={addTipoAmbienteDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAddTipoAmbienteDialog}
                aria-labelledby="add-tipo-ambiente-dialog"
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>{`Aggiungi Tipo Ambiente`}</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="tipo_ambiente"
                                label="Tipo Ambiente"
                                name="tipo_ambiente"
                                value={tipoAmbienteAdd}
                                onChange={handleChangeTipoAmbienteAdd}
                                error={tipoAmbienteAddError}
                            />
                            <Grid
                                style={{
                                    marginTop: theme.spacing(2),
                                    marginBottom: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    onClick={handleCloseAddTipoAmbienteDialog}
                                    variant="contained"
                                    disabled={isLoading}
                                >
                                    {'Annulla'}
                                </Button>
                                <Button
                                    onClick={handleClickSaveAddTipoAmbiente}
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <CircularProgress size="2em" color='primary' />
                                    ) : (
                                        <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </form>
    );
}

export default CreateDevice;