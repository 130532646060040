import React, { useContext, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FaBars, FaSignOutAlt, FaUser } from 'react-icons/fa';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import Logo from '../assets/images/logorefineair.png';
import { properties } from '../configs/properties';
import CustomContext from '../context/CustomContext';
import { Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        /* height: '150px', */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    content: {
        /* flexGrow: 1,
        padding: theme.spacing(3), */
        flexGrow: 1,
        marginTop: theme.spacing(8),
        /* height: `calc(100vh - ${theme.spacing(8)}px)`, */
        overflowY: 'auto'
    },
}));

function Layout({ component: Component, handleChangeTheme, ...rest }) {
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [userLogged, setUserLogged] = React.useState(null);
    const [logoUrl, setLogoUrl] = React.useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        Cookies.remove(properties.tokenKey);
        window.location = '/login';
    }

    const findLogo = () => {
        let logo = ctx.getLogo();
        if (!logo) {
            ctx.findLogo()
                .then(result => {
                    if (result?.status === 200) {
                        result.blob()
                            .then(image => {
                                if (image && image?.size) {
                                    ctx.setLogo(image);
                                    setLogoUrl(URL.createObjectURL(image))
                                }
                            });
                    } else if (result?.status === 404) {
                        return null;
                    } else if (result?.status === 401) {
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
        } else {
            setLogoUrl(URL.createObjectURL(logo));
        }
    }

    useEffect(() => {
        let user = ctx.getUserCookie();
        setUserLogged(user);
        if (user?.primaryColor && user?.secondaryColor) {
            handleChangeTheme({
                palette: {
                    primary: {
                        main: user.primaryColor
                    },
                    secondary: {
                        main: user.secondaryColor
                    }
                }
            })
        }
        findLogo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const drawer = (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{width: '100%'}}>
                <div className={classes.toolbar} >
                    <img src={logoUrl !== null ? logoUrl : Logo} alt='logo' style={{ height: '100px', width: '100%' }} />
                </div>
                <Divider style={{ backgroundColor: 'white' }} />
                <List>
                    {
                        ctx.getRoutesByRole().map(route => {
                            return (
                                route.display && <ListItem key={route.key} button onClick={() => { history.push(route.path); setMobileOpen(false) }}>
                                    <ListItemIcon>
                                        <route.icon size={'2em'} color={theme.palette.secondary.main} />
                                    </ListItemIcon>
                                    <ListItemText primary={route.title} />
                                </ListItem>
                            )
                        })
                    }
                </List>
                <Divider style={{ backgroundColor: 'white' }} />
                <List>
                    <Tooltip title={userLogged?.username ? userLogged.username : ''}>
                        <ListItem key='username' >
                            <ListItemIcon><FaUser size='2em' color={theme.palette.secondary.main}></FaUser></ListItemIcon>
                            <ListItemText primary={userLogged?.username.substr(0, 12) + (userLogged?.username?.length > 12 ? '...' : '')} />
                        </ListItem>
                    </Tooltip>
                    <ListItem button key='logout' onClick={handleLogout} >
                        <ListItemIcon><FaSignOutAlt size={'2em'} color={theme.palette.secondary.main} /></ListItemIcon>
                        <ListItemText primary={`Logout`} />
                    </ListItem>
                </List>
            </div>
            <div>
                <span style={{ bottom: '0px' }}>
                    {'Powered by RefineAir'}
                </span>
            </div>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <FaBars />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {'RefineAir Management'}
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden implementation="css" mdUp >
                    <Drawer
                        variant="temporary"
                        anchor={'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                {/* <div style={{ height: '30px' }} /> */}
                <Component handleChangeTheme={handleChangeTheme} />
            </main>
        </div>
    );
}

export default Layout;