import { Card, CardHeader, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { properties } from '../configs/properties';
import CustomContext from '../context/CustomContext';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    cardContent: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', padding: theme.spacing(2)
    }
}))

const colors = [
    '#647CB8',
    '#3F4F75',
    '#84A4F5',
    '#1D2436',
    '#7693DB',
    '#4D5AB8',
    '#313975',
    '#6777F5',
    '#161A36',
    '#5D6CDB'
]

const chartLoader = () => {
    return (
        <div style={{ height: '300px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><CircularProgress color='primary' /></div>
    )
}

const DashboardUser = () => {
    const classes = useStyle();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [tipoAmbienteAnalyticsData, setTipoAmbienteAnalyticsData] = useState([]);
    const [puntoCampionamentoAnalyticsData, setPuntoCampionamentoAnalyticsData] = useState([]);
    const [reportAnalytics, setReportAnalytics] = useState();

    useEffect(() => {
        ctx.getUserDashboardAnalytics()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then((jsonRes) => {
                if (jsonRes) {
                    let taaData = jsonRes.tipoAmbienteAnalytics?.map((a, index) => {

                        return [
                            a.nome, a.count
                        ]
                    })
                    let pcaData = jsonRes.puntoCampionamentoAnalytics?.map((a, index) => {

                        return [
                            a.nome, a.count
                        ]
                    })

                    setReportAnalytics(jsonRes.reportAnalytics);
                    setTipoAmbienteAnalyticsData(taaData);
                    setPuntoCampionamentoAnalyticsData(pcaData);
                }
            })
            .catch(e => {
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.container} >
            <Typography variant="h4" color='textSecondary' className={classes.title}>{"Statistiche generali"}</Typography>
            <Grid
                container
                spacing={3}
            >
                <Grid item md={6} xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <CardHeader
                            title={"Tipo Ambiente"}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'textSecondary'
                            }}
                        />
                        <Grid className={classes.cardContent}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="PieChart"
                                loader={chartLoader()}
                                data={[
                                    ['Tipo Ambiente', 'Valore'],
                                    ...tipoAmbienteAnalyticsData
                                ]}
                                options={{
                                    title: '',
                                    is3D: true,
                                    colors: colors,
                                    chartArea: { width: '100%' }
                                }}
                            />
                        </Grid>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <CardHeader
                            title={"Punto di campionamento"}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'textSecondary'
                            }}
                        />
                        <Grid className={classes.cardContent}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="PieChart"
                                loader={chartLoader()}
                                data={[
                                    ['Punto Campionamento', 'Valore'],
                                    ...puntoCampionamentoAnalyticsData
                                ]}
                                options={{
                                    title: '',
                                    is3D: true,
                                    colors: colors,
                                    chartArea: { width: '100%' }
                                }}
                            />
                        </Grid>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <CardHeader
                            title={"Microrganismi"}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'textSecondary'
                            }}
                        />
                        <Grid className={classes.cardContent}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="Bar"
                                loader={chartLoader()}
                                data={[
                                    ['', 'min', 'media', 'max'],
                                    ['Conta Microrganismi', reportAnalytics?.minContaMicrorganismi, reportAnalytics?.avgContaMicrorganismi, reportAnalytics?.maxContaMicrorganismi]
                                ]}
                                options={{
                                    title: '',
                                    is3D: true,
                                    colors: colors,
                                    chartArea: { width: '100%' }
                                }}
                            />
                        </Grid>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <CardHeader
                            title={"Lieviti"}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'textSecondary'
                            }}
                        />
                        <Grid className={classes.cardContent}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="Bar"
                                loader={chartLoader()}
                                data={[
                                    ['', 'min', 'media', 'max'],
                                    ['Conta Lieviti', reportAnalytics?.minContaLieviti, reportAnalytics?.avgContaLieviti, reportAnalytics?.maxContaLieviti]
                                ]}
                                options={{
                                    title: '',
                                    is3D: true,
                                    colors: colors,
                                    chartArea: { width: '100%' }
                                }}
                            />
                        </Grid>
                    </Card>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <CardHeader
                            title={"Controllo microbiologico"}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'textSecondary'
                            }}
                        />
                        <Grid className={classes.cardContent}>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="Bar"
                                loader={chartLoader()}
                                data={[
                                    ['', 'min', 'media', 'max'],
                                    ['Controllo Microbiologico', reportAnalytics?.minControlloMicrobiologico, reportAnalytics?.avgControlloMicrobiologico, reportAnalytics?.maxControlloMicrobiologico]
                                ]}
                                options={{
                                    title: '',
                                    is3D: true,
                                    colors: colors,
                                    chartArea: { width: '100%' }
                                }}
                            />
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default DashboardUser;