import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button, Card, CardHeader, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, MenuItem, Slide, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import { FaChevronDown, FaChevronUp, FaSearch, FaPencilAlt, FaPlus, FaTrash, FaUserCircle } from 'react-icons/fa';
import { ListItem } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hover: {
        "&:hover": {
            backgroundColor: 'lightgray'
        }
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AdminDeviceList = () => {
    //CONSTANTS
    const daysFrom = 30;
    const pageSize = 1;
    const page = 1;
    const order = 1;

    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [deviceList, setDeviceList] = useState([]);
    const [count, setCount] = useState(0);
    const [deviceResponse, setDeviceResponse] = useState('');
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [reset, setReset] = useState(false);
    //////////////////////////////////////////
    const [tipoAmbiente, setTipoAmbiente] = useState('');
    const [tipoAmbienteEnum, setTipoAmbienteEnum] = useState([]);
    const [nome, setNome] = useState('');
    const [installers, setInstallers] = useState([]);
    const [selectedInstaller, setSelectedInstaller] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editDialogTipoAmbiente, setEditDialogTipoAmbiente] = useState('');
    const [editDialogNome, setEditDialogNome] = useState('');
    const [editDialogNomeError, setEditDialogNomeError] = useState(false);
    const [editDialogTopic, setEditDialogTopic] = useState('');
    const [editDialogTopicError, setEditDialogTopicError] = useState(false);
    const [editDialogTopicList, setEditDialogTopicList] = useState([]);
    const [editDialogDeviceId, setEditDialogDeviceId] = useState('');
    const [editDialogDeviceIdError, setEditDialogDeviceIdError] = useState(false);
    const [editDialogSerialNumber, setEditDialogSerialNumber] = useState('');
    const [editDialogSerialNumberError, setEditDialogSerialNumberError] = useState(false);
    const [editDialogTipoProdotto, setEditDialogTipoProdotto] = useState('');
    const [selectedEditDevice, setSelectedEditDevice] = useState(null);
    const [selectedViewTopics, setSelectedViewTopics] = useState(null);
    const [selectedViewSerialNumber, setSelectedViewSerialNumber] = useState('');
    const [topicsDialogOpen, setTopicsDialogOpen] = useState(false);
    const [topicPayloadDialogOpen, setTopicPayloadDialogOpen] = useState(false);
    const [selectedViewTopic, setSelectedViewTopic] = useState('');
    const [topicEnum, setTopicEnum] = useState([]);
    const [clientiEnum, setClientiEnum] = useState([]);
    const [editDialogCliente, setEditDialogCliente] = useState('');
    const [editDialogClienteError, setEditDialogClienteError] = useState(false);
    const [filterCliente, setFilterCliente] = useState('');
    const [tipoDeviceEnum, setTipoDeviceEnum] = useState([]);

    const handleChangeFilterCliente = (e) => {
        setFilterCliente(e.target.value);
    }

    const handleChangeEditCliente = (e) => {
        setEditDialogClienteError(false);
        setEditDialogCliente(e.target.value);
    }

    const handleChangeTipoAmbiente = (e) => {
        setTipoAmbiente(e.target.value);
    }

    const handleChangeNome = (e) => {
        setNome(e.target.value);
    }

    const handleChangeInstallatore = (e, newValue) => {
        setSelectedInstaller(newValue);
    }

    const handleClickResetFilter = () => {
        setTipoAmbiente('');
        setNome('');
        setFilterCliente('');
        setSelectedInstaller(null);
        setReset(true);
    }

    const handleClickFilter = () => {
        getData();
    }

    const getData = async () => {
        setLoading(true);
        let filters = {
            idTipoAmbiente: tipoAmbiente,
            idUtenteInstallatore: selectedInstaller?.id,
            nome: nome,
            idCliente: filterCliente,
            limit: limit,
            offset: offset
        }
        ctx.getAdminDeviceList(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setDeviceList(jsonRes.deviceList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const initEnums = () => {
        setLoading(true);
        ctx.getAdminDeviceListEnums()
            .then(res => {
                if (res?.status === 200) {
                    return res.json();
                } else if (res?.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    // error
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setTipoAmbienteEnum(jsonRes.tipoAmbienteDomainList);
                    setInstallers(jsonRes.installatori);
                    setTopicEnum(jsonRes.topicDomainList);
                    setClientiEnum(jsonRes.clienti);
                    setTipoDeviceEnum(jsonRes.tipoDeviceDomainList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const handleChangeEditDialogTipoAmbiente = (e) => {
        setEditDialogTipoAmbiente(e.target.value);
    }

    const handleChangeEditTipoProdotto = (e) => {
        setEditDialogTipoProdotto(e.target.value);
    }

    const handleChangeEditNome = (e) => {
        setEditDialogNome(e.target.value);
        setEditDialogNomeError(false);
    }

    const handleChangeEditTopic = (e) => {
        setEditDialogTopic(e.target.value);
        setEditDialogTopicError(false);
    }

    const handleChangeEditDeviceId = (e) => {
        setEditDialogDeviceId(e.target.value);
        setEditDialogDeviceIdError(false);
    }

    const handleChangeEditSerialNumber = (e) => {
        setEditDialogSerialNumber(e.target.value);
        setEditDialogSerialNumberError(false);
    }

    const validation = () => {
        let result = true;

        if (!editDialogNome || editDialogNome?.length === 0) {
            result = false;
            setEditDialogNomeError(true);
        }

        if (!editDialogTopicList || editDialogTopicList?.length === 0) {
            result = false;
            setEditDialogTopicError(true);
        }

        if (!editDialogSerialNumber || editDialogSerialNumber?.length === 0) {
            result = false;
            setEditDialogSerialNumberError(true);
        }

        if (!editDialogDeviceId || editDialogDeviceId?.length === 0) {
            result = false;
            setEditDialogDeviceIdError(true);
        }

        if (!editDialogCliente || editDialogCliente?.length === 0) {
            result = false;
            setEditDialogClienteError(true);
        }

        return result;
    }

    const getEditDevice = () => {
        return {
            id: selectedEditDevice?.id,
            nome: editDialogNome,
            topic: editDialogTopicList,
            deviceId: editDialogDeviceId,
            serialNumber: editDialogSerialNumber,
            idTipoAmbiente: editDialogTipoAmbiente,
            tipoProdotto: editDialogTipoProdotto,
            idCliente: editDialogCliente
        }
    }

    const handleClickSaveEdit = () => {
        if (validation()) {
            setLoading(true);
            ctx.updateDevice(getEditDevice())
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                        setLoading(false);
                        ctx.logout();
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes === true) {
                        setLoading(false);
                        handleCloseEditDeviceDialog();
                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                        getData();
                    }
                })
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        }
    }

    const handleClickEditDevice = (row) => {
        let selectedDevice = {
            nome: row[0],
            topicList: row[1]?.map(t => { return t.topicDomain }),
            deviceId: row[8],
            serialNumber: row[2],
            tipoAmbiente: row[3],
            tipoProdotto: row[6],
            id: row[7],
            cliente: row[5]
        };
        setSelectedEditDevice(selectedDevice);
        setEditDialogTipoAmbiente(selectedDevice.tipoAmbiente?.id);
        setEditDialogNome(selectedDevice.nome);
        setEditDialogTopicList(selectedDevice.topicList);
        setEditDialogSerialNumber(selectedDevice.serialNumber);
        setEditDialogDeviceId(selectedDevice.deviceId);
        setEditDialogTipoProdotto(selectedDevice.tipoProdotto?.id);
        setEditDialogCliente(selectedDevice.cliente?.id);
        setEditDialogTopic('');
        setEditDialogOpen(true);

    }

    const handleCloseEditDeviceDialog = () => {
        setEditDialogOpen(false);
        setEditDialogNome('');
        setEditDialogNomeError(false);
        setEditDialogTopic('');
        setEditDialogTopicList([]);
        setEditDialogTopicError(false);
        setEditDialogSerialNumber('');
        setEditDialogSerialNumberError(false);
        setEditDialogTipoAmbiente('');
        setEditDialogDeviceId('');
        setSelectedEditDevice(null);
        setEditDialogClienteError(false);
        setEditDialogCliente('');
    }

    const handleClickAddEditTopic = () => {
        if (!editDialogTopic || editDialogTopic?.length === 0) {
            setEditDialogTopicError(true);
        } else {
            if (editDialogTopicList.filter(t => t.id === editDialogTopic).length === 0) {
                setEditDialogTopicList([topicEnum.filter(t => t.id === editDialogTopic)[0], ...editDialogTopicList]);
            }
            setEditDialogTopic('');
        }
    }

    const handleRemoveTopic = (topic) => {
        setEditDialogTopicList(editDialogTopicList.filter(t => t !== topic));
    }

    const handleOpenPayloadDialog = () => {
        setTopicsDialogOpen(false);
        setTopicPayloadDialogOpen(true);
    }

    const handleClosePayloadDialog = () => {
        setDeviceResponse('');
        setSelectedViewSerialNumber('');
        setTopicPayloadDialogOpen(false);
        setTopicsDialogOpen(true);
    }

    const handleClickViewTopicPayload = (topic) => {
        setLoading(true);
        setSelectedViewTopic(topic);
        ctx.getDeviceTopicResponse(topic, selectedViewSerialNumber, daysFrom, pageSize, page, order)
            .then(res => {
                if (res?.status === 200) {
                    return res.json();
                } else if (res?.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    // error
                    setLoading(false);
                    setSelectedViewTopic('');
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    if (jsonRes.length > 0) {
                        let response = '';
                        if(['ORE_LAMPADA', 'ORE_VENTOLA'].includes(topic)){
                            response = Math.ceil((Number(jsonRes[0]?.payload) * 10) / 60);
                        } else {
                            response = jsonRes[0]?.payload;
                        }
                        setDeviceResponse(response);
                        handleOpenPayloadDialog();
                    } else {
                        enqueueSnackbar('Non sono presenti messaggi su questo topic.', { variant: 'error' });
                    }
                    setLoading(false);
                    setSelectedViewTopic('');
                } else {
                    enqueueSnackbar('Errore nella lettura del payload del dispositivo.');
                    setLoading(false);
                    setSelectedViewTopic('');
                }
            })
            .catch(e => {
                setLoading(false);
                setSelectedViewTopic('');
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const handleViewTopics = (topics, rowSerialNumber) => {
        setSelectedViewTopics(topics);
        setSelectedViewSerialNumber(rowSerialNumber);
        setTopicsDialogOpen(true);
    }

    const handleCloseTopicsDialog = () => {
        setTopicsDialogOpen(false);
        setSelectedViewTopics(null);
        setSelectedViewSerialNumber('');
    }

    useEffect(() => {
        initEnums();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        getData()
            .then(() => {
                setReset(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    const columns = [
        {
            name: "nome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Nome"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 15) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 12) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "dispositivoTopicDomainList",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Topic"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    return <div className={classes.centered} >
                        {
                            value?.map((c, index) => {
                                return index < 2 ? <Tooltip title={c.topicDomain.nome} key={c.id}><div style={{ padding: '5px', marginRight: '3px', backgroundColor: theme.palette.primary.main, borderRadius: '8px' }}>
                                    <span style={{ color: 'white' }}>{c.topicDomain.nome?.length > 6 ? c.topicDomain.nome?.substring(0, 6) + '...' : c.topicDomain.nome}</span>
                                </div>
                                </Tooltip>
                                    :
                                    ''
                            })
                        }
                        {
                            value?.length > 0 && <div
                                style={{
                                    padding: '5px',
                                    marginRight: '3px',
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    color: 'white'
                                }}
                                onClick={() => { handleViewTopics(value, tableData?.rowData?.length > 0 ? tableData?.rowData[2] : '') }}
                            >
                                {value?.length > 2 ? ('+' + (value?.length - 2)) : <FaSearch color='white' />}
                            </div>
                        }
                    </div>
                }
            }
        },
        {
            name: "serialNumber",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Seriale"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "tipoAmbienteDomain",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Tipo ambiente"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "utenteInstallatore",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Manutentore"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome + ' ' + value?.cognome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "utenteCliente",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Cliente"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome + ' ' + value?.cognome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "tipoProdotto",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Tipo prodotto"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "id",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Azioni"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {

                    return <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <FaSearch color={theme.palette.primary.main} size='30' style={{ cursor: 'pointer' }} onClick={() => { handleClickViewDevice(value) }} /> */}
                        <FaPencilAlt color={theme.palette.primary.main} size='30' style={{ cursor: 'pointer', marginLeft: theme.spacing(1) }} onClick={() => { handleClickEditDevice(tableData.rowData) }} />
                    </div>
                }
            }
        },
        {
            name: "deviceId",
            options: {
                display: false
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{'Lista di dispositivi installati'}</Typography>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>

                            <Grid container style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant='h6' color='textSecondary'>
                                                    {'Filtri'}
                                                </Typography>
                                                {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                    : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                            </div>}
                                            onClick={() => { setFiltersVisible(!filtersVisible) }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Collapse in={filtersVisible}>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        id="tipo_ambiente"
                                                        select
                                                        label="Tipo di ambiente"
                                                        value={tipoAmbiente}
                                                        onChange={handleChangeTipoAmbiente}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                    >
                                                        <MenuItem value=''>
                                                            <em>
                                                                {'Selezionare tipo ambiente'}
                                                            </em>
                                                        </MenuItem>
                                                        {tipoAmbienteEnum.map((ta) => {
                                                            return (
                                                                <MenuItem key={ta.id} value={ta.id}>
                                                                    {ta.nome}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="nome"
                                                        label="Nome"
                                                        name="nome"
                                                        value={nome}
                                                        onChange={handleChangeNome}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <Autocomplete
                                                        id="installatori"
                                                        options={installers}
                                                        value={selectedInstaller}
                                                        onChange={handleChangeInstallatore}
                                                        getOptionLabel={(option) => (option.nome + ' ' + option.cognome)}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                label="Manutentore"
                                                                variant="outlined"
                                                                fullWidth
                                                                margin='normal'
                                                            />
                                                        }
                                                        renderOption={(option) =>
                                                            <React.Fragment>
                                                                <Avatar style={{ marginRight: '10px' }}>
                                                                    <FaUserCircle />
                                                                </Avatar>
                                                                {option.nome + ' ' + option.cognome}
                                                            </React.Fragment>
                                                        }
                                                        ListboxProps={{
                                                            style: {
                                                                border: '1px solid rgba(0,0,0,0.2)',
                                                                borderRadius: '3px'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        id="clienti"
                                                        select
                                                        label="Cliente"
                                                        value={filterCliente}
                                                        onChange={handleChangeFilterCliente}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                    >
                                                        {clientiEnum.map((c) => {
                                                            return (
                                                                <MenuItem key={c.id} value={c.id}>
                                                                    {c.nome + ' ' + c.cognome}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    md={12}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={handleClickResetFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={handleClickFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: theme.spacing(1) }}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            </Grid>
                            <MUIDataTable
                                data={deviceList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                }

                <Dialog
                    open={topicsDialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseTopicsDialog}
                    aria-labelledby="topics-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Topic del dispositivo`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            {
                                selectedViewTopics?.map(topic => {
                                    return <React.Fragment key={topic.id}>
                                        <Divider style={{ width: '100%', backgroundColor: 'lightgray' }} />
                                        <Grid item xs={12} className={classes.hover} style={{ padding: theme.spacing(1), display: 'flex', justifyContent: 'space-between' }}>
                                            <span style={{ fontWeight: 'bold' }}>{topic.topicDomain.nome}</span>
                                            {isLoading && (topic.topicDomain.nome === selectedViewTopic) && <CircularProgress color={'primary'} size={theme.spacing(2)} />}
                                            {!isLoading && <FaSearch color={theme.palette.primary.main} style={{ cursor: 'pointer' }} onClick={() => handleClickViewTopicPayload(topic.topicDomain.nome)} />}
                                        </Grid>
                                    </React.Fragment>
                                })
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseTopicsDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Chiudi"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={topicPayloadDialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClosePayloadDialog}
                    aria-labelledby="edit-device-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Payload topic`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span>{deviceResponse}</span>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClosePayloadDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Indietro"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={editDialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseEditDeviceDialog}
                    aria-labelledby="edit-device-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Modifica dispositivo`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="nome_edit"
                                    label="Nome"
                                    name="nome"
                                    value={editDialogNome}
                                    error={editDialogNomeError}
                                    onChange={handleChangeEditNome}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="deviceId"
                                    label="Device ID"
                                    name="deviceId"
                                    value={editDialogDeviceId}
                                    error={editDialogDeviceIdError}
                                    onChange={handleChangeEditDeviceId}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="serialNumber"
                                    label="Seriale"
                                    name="serialNumber"
                                    value={editDialogSerialNumber}
                                    error={editDialogSerialNumberError}
                                    onChange={handleChangeEditSerialNumber}
                                />
                                <TextField
                                    id="edit_tipo_ambiente"
                                    select
                                    label="Tipo ambiente"
                                    value={editDialogTipoAmbiente}
                                    onChange={handleChangeEditDialogTipoAmbiente}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    <MenuItem value=''>
                                        <em>
                                            {'Selezionare tipo ambiente'}
                                        </em>
                                    </MenuItem>
                                    {tipoAmbienteEnum.map((ta) => {
                                        return (
                                            <MenuItem key={ta.id} value={ta.id}>
                                                {ta.nome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <TextField
                                    id="edit_tipo_prodotto"
                                    select
                                    label="Tipo Prodotto"
                                    value={editDialogTipoProdotto}
                                    onChange={handleChangeEditTipoProdotto}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    {tipoDeviceEnum.map((c) => {
                                        return (
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.nome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <TextField
                                    id="clienti"
                                    select
                                    label="Cliente"
                                    value={editDialogCliente}
                                    onChange={handleChangeEditCliente}
                                    error={editDialogClienteError}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                >
                                    {clientiEnum.map((c) => {
                                        return (
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.nome + ' ' + c.cognome}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                                <Grid item container xs={12}>
                                    <Grid
                                        item xs={9}
                                    >
                                        <TextField
                                            id="topic"
                                            select
                                            label="Topic"
                                            value={editDialogTopic}
                                            error={editDialogTopicError}
                                            onChange={handleChangeEditTopic}
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                        >
                                            <MenuItem value=''>
                                                <em>
                                                    {'Selezionare topic'}
                                                </em>
                                            </MenuItem>
                                            {topicEnum.map((ta) => {
                                                return (
                                                    <MenuItem key={ta.id} value={ta.id}>
                                                        {ta.nome}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                    <Grid
                                        item xs={3}
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <Button
                                            onClick={handleClickAddEditTopic}
                                            variant="contained"
                                            color="primary"
                                        >
                                            <FaPlus color='white' />
                                        </Button>
                                    </Grid>
                                    {
                                        editDialogTopicList?.map((item, index) => {
                                            return <ListItem button key={index}>
                                                <Grid item xs={9}>
                                                    {item.nome}
                                                </Grid>
                                                <Grid item xs={3}
                                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                >
                                                    <Button
                                                        onClick={() => handleRemoveTopic(item)}
                                                        variant="text"
                                                    >
                                                        <FaTrash color='red' />
                                                    </Button>
                                                </Grid>
                                            </ListItem>
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseEditDeviceDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Chiudi"}</span>
                        </Button>
                        <Button
                            onClick={handleClickSaveEdit}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </MuiPickersUtilsProvider>

    )
}

export default AdminDeviceList;