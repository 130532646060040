import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import { FaPencilAlt } from 'react-icons/fa';
import Transition from '../components/Transition';


const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const TopicList = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [topicList, setTopicList] = useState([]);
    const [count, setCount] = useState(0);
    const [editTopicDialogVisible, setEditTopicDialogVisible] = useState(false);
    const [editDialogId, setEditDialogId] = useState(0);
    const [editDialogNome, setEditDialogNome] = useState('');
    const [editDialogNomeError, setEditDialogNomeError] = useState(false);
    const [insertTopicDialogVisible, setInsertTopicDialogVisible] = useState(false);
    const [insertDialogNome, setInsertDialogNome] = useState('');
    const [insertDialogNomeError, setInsertDialogNomeError] = useState(false);

    const handleClickEditTopic = (row) => {
        let user = {
            id: row[1],
            nome: row[0]
        }

        setEditDialogId(user.id);
        setEditDialogNome(user.nome);
        setEditTopicDialogVisible(true);
    }

    const handleClickInsertTopic = (row) => {
        setInsertDialogNome('');
        setInsertTopicDialogVisible(true);
    }

    const handleChangeInsertNome = (e) => {
        setInsertDialogNome(e.target.value);
        setInsertDialogNomeError(false);
    }

    const handleChangeEditNome = (e) => {
        setEditDialogNome(e.target.value);
        setEditDialogNomeError(false);
    }

    const handleCloseInsertTopicDialog = () => {
        setInsertDialogNomeError(false);
        setInsertDialogNome('');
        setInsertTopicDialogVisible(false);
    }

    const handleCloseEditTopicDialog = () => {
        setEditDialogNomeError(false);
        setEditDialogNome('');
        setEditTopicDialogVisible(false);
    }

    const getTableTitle = () => {
        return 'Lista topic';
    }

    const validationInsertDialog = () => {
        let result = true;

        if (!insertDialogNome || insertDialogNome?.length === 0) {
            setInsertDialogNomeError(true);
            result = false;
        }

        return result;
    }

    const validationEditDialog = () => {
        let result = true;

        if (!editDialogNome || editDialogNome?.length === 0) {
            setEditDialogNomeError(true);
            result = false;
        }

        return result;
    }

    const handleClickSaveEditTopic = () => {
        if (validationEditDialog()) {
            setLoading(true);
            ctx.updateTopic({ id: editDialogId, nome: editDialogNome })
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                        setLoading(false);
                        ctx.logout();
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes) {
                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                        handleCloseEditTopicDialog();
                        getData();
                    }
                })
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        }
    }

    const handleClickSaveInsertTopic = () => {
        if (validationInsertDialog()) {
            setLoading(true);
            ctx.topicExistsByName(insertDialogNome)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                        setLoading(false);
                        ctx.logout();
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes === false) {
                        ctx.insertTopic({ nome: insertDialogNome })
                            .then(res => {
                                if (res.status === 200) {
                                    return res.json();
                                } else if (res.status === 401) {
                                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                                    setLoading(false);
                                    ctx.logout();
                                } else {
                                    setLoading(false);
                                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                    return null;
                                }
                            })
                            .then(jsonRes => {
                                if (jsonRes) {
                                    enqueueSnackbar(properties.successMessage, { variant: 'success' });
                                    handleCloseInsertTopicDialog();
                                    getData();
                                }
                            })
                            .catch(e => {
                                setLoading(false);
                                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                            })
                    } else {
                        enqueueSnackbar('Topic già esistente', { variant: 'warning' });
                        setLoading(false);
                    }
                })
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        }
    }

    const getData = async (reset = false) => {
        setLoading(true);
        let filters = {
            offset: offset,
            limit: limit
        }
        ctx.topicList(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setTopicList(jsonRes.topicDomainList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    const columns = [
        {
            name: "nome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Nome"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (!formatted) {
                        return <div style={{ color: 'lightgray', fontStyle: 'italic' }} className={classes.centered} >{'Nome non inserito'}</div>;
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "id",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Topic"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {<FaPencilAlt style={{ cursor: 'pointer' }} onClick={() => { handleClickEditTopic(tableData.rowData) }} />}
                    </div>
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    return (
        <div>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{getTableTitle()}</Typography>
                <Grid item xs={12} style={{ paddingBottom: theme.spacing(2), display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        onClick={handleClickInsertTopic}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isLoading}
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        {'Nuovo Topic'}
                    </Button>
                </Grid>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>
                            <MUIDataTable
                                data={topicList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                }
                <Dialog
                    open={editTopicDialogVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseEditTopicDialog}
                    aria-labelledby="edit-topic-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Modifica Topic`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="nome_edit"
                                    label="Nome"
                                    name="nome"
                                    value={editDialogNome}
                                    error={editDialogNomeError}
                                    onChange={handleChangeEditNome}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseEditTopicDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Chiudi"}</span>
                        </Button>
                        <Button
                            onClick={handleClickSaveEditTopic}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={insertTopicDialogVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseInsertTopicDialog}
                    aria-labelledby="insert-topic-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Nuovo Topic`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="nome_insert"
                                    label="Nome"
                                    name="nome"
                                    value={insertDialogNome}
                                    error={insertDialogNomeError}
                                    onChange={handleChangeInsertNome}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseInsertTopicDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Chiudi"}</span>
                        </Button>
                        <Button
                            onClick={handleClickSaveInsertTopic}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>

    )
}

export default TopicList;