import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import Layout from '../components/Layout';
import { properties } from './properties';

const PrivateRoute = ({ component: Component, handleChangeTheme, ...rest }) => {
    return (
        <>
            <Route
                {...rest}
                render={props => Cookies.get(properties.tokenKey) ? (<Layout {...props} handleChangeTheme={handleChangeTheme} component={Component} window={window} />) : (<Redirect to='/login' />)}
            />
        </>
    )
}

export default PrivateRoute;