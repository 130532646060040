import { FaBusinessTime, FaChartLine, FaClipboardList, FaCogs, FaFilePdf, FaFolderOpen, FaGlasses, FaHistory, FaHome, FaListOl, FaMicrochip, FaUsers } from "react-icons/fa";
import CreateReport from "../pages/CreateReport";
import Dashboard from "../pages/Dashboard";
import SuperadminSettings from "../pages/SuperadminSettings";
import AdminSettings from "../pages/AdminSettings";
import ReportList from "../pages/ReportList";
import CompleteProfile from "../pages/CompleteProfile";
import VoidDashboard from "../pages/VoidDashboard";
import Logs from "../pages/Logs";
import UserList from "../pages/UserList";
import Progress from "../pages/Progress";
import CreateDevice from "../pages/CreateDevice";
import DeviceList from "../pages/DeviceList";
import AdminDeviceList from "../pages/AdminDeviceList";
import DeviceHistoryList from "../pages/DeviceHistoryList";
import DashboardUser from "../pages/DashboardUser";
import DashboardAdmin from "../pages/DashboardAdmin";
import DeviceReadList from "../pages/DeviceReadList";
import Dimensions from "../pages/Dimensions";
import TopicList from "../pages/TopicList";
import AccountTopicList from "../pages/AccountTopicList";
import ImportProductionCode from "../pages/ImportProductonCode";
import ProductionCodeList from "../pages/ProductionCodeList";
import ExportProductionCode from "../pages/ExportProductonCode";


export const superadminRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: DashboardAdmin,
        display: true
    },
    {
        key: 'report-list',
        title: 'Lista report',
        icon: FaFolderOpen,
        path: '/reportlist',
        component: ReportList,
        display: true
    },
    {
        key: 'user-list',
        title: 'Lista manutentori',
        icon: FaUsers,
        path: '/userlist',
        component: UserList,
        display: true
    },
    {
        key: 'device-list',
        title: 'Lista Dispositivi',
        icon: FaListOl,
        path: '/devicelist',
        component: AdminDeviceList,
        display: true
    },
    {
        key: 'device-history-list',
        title: 'Storico Dispositivi',
        icon: FaHistory,
        path: '/devicehistorylist',
        component: DeviceHistoryList,
        display: true
    },
    {
        key: 'device-read-list',
        title: 'Lista Letture Dispositivi',
        icon: FaGlasses,
        path: '/devicereadlist',
        component: DeviceReadList,
        display: true
    },
    {
        key: 'dimensions',
        title: 'Anagrafiche',
        icon: FaUsers,
        path: '/dimensions',
        component: Dimensions,
        display: true
    },
    {
        key: 'superadmin-settings',
        title: 'Impostazioni',
        icon: FaCogs,
        path: '/superadmin/impostazioni',
        component: SuperadminSettings,
        display: true
    },
    {
        key: 'topic-list',
        title: 'TopicList',
        icon: FaCogs,
        path: '/topicList',
        component: TopicList,
        display: false
    },
    {
        key: 'account-topic-list',
        title: 'AccountTopicList',
        icon: FaCogs,
        path: '/accountTopicList',
        component: AccountTopicList,
        display: false
    }
];

//installer
export const adminRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard,
        display: true
    },
    {
        key: 'report',
        title: 'Report',
        icon: FaFilePdf,
        path: '/report',
        component: CreateReport,
        display: true
    },
    {
        key: 'report-list',
        title: 'Lista report',
        icon: FaFolderOpen,
        path: '/reportlist',
        component: ReportList,
        display: true
    },
    {
        key: 'device',
        title: 'Nuovo Dispositivo',
        icon: FaMicrochip,
        path: '/device',
        component: CreateDevice,
        display: true
    },
    {
        key: 'device-list',
        title: 'Lista Dispositivi',
        icon: FaListOl,
        path: '/devicelist',
        component: DeviceList,
        display: true
    },
    {
        key: 'device-history-list',
        title: 'Storico Dispositivi',
        icon: FaHistory,
        path: '/devicehistorylist',
        component: DeviceHistoryList,
        display: true
    },
    {
        key: 'progress',
        title: 'Andamento',
        icon: FaChartLine,
        path: '/progress',
        component: Progress,
        display: true
    },
    {
        key: 'production-code',
        title: 'Turni/Prod.',
        icon: FaBusinessTime,
        path: '/production-code',
        component: ImportProductionCode,
        display: true
    },
    {
        key: 'production-code-list',
        title: 'Lista Turni/Produzione',
        icon: FaBusinessTime,
        path: '/production-code-list',
        component: ProductionCodeList,
        display: false
    },
    {
        key: 'admin-settings',
        title: 'Impostazioni',
        icon: FaCogs,
        path: '/admin/impostazioni',
        component: AdminSettings,
        display: true
    },
    {
        key: 'export-production-code',
        title: 'Export Turni/Produzione',
        icon: FaBusinessTime,
        path: '/export-production-code',
        component: ExportProductionCode,
        display: false
    }
]

export const userRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: DashboardUser,
        display: true
    },
    {
        key: 'report',
        title: 'Report',
        icon: FaFilePdf,
        path: '/report',
        component: CreateReport,
        display: true
    },
    {
        key: 'report-list',
        title: 'Lista report',
        icon: FaFolderOpen,
        path: '/reportlist',
        component: ReportList,
        display: true
    },
    {
        key: 'device',
        title: 'Nuovo Dispositivo',
        icon: FaMicrochip,
        path: '/device',
        component: CreateDevice,
        display: true
    },
    {
        key: 'device-list',
        title: 'Lista Dispositivi',
        icon: FaListOl,
        path: '/devicelist',
        component: DeviceList,
        display: true
    },
    {
        key: 'device-history-list',
        title: 'Storico Dispositivi',
        icon: FaHistory,
        path: '/devicehistorylist',
        component: DeviceHistoryList,
        display: true
    },
    {
        key: 'progress',
        title: 'Andamento',
        icon: FaChartLine,
        path: '/progress',
        component: Progress,
        display: true
    },
    {
        key: 'production-code',
        title: 'Turni/Prod.',
        icon: FaBusinessTime,
        path: '/production-code',
        component: ImportProductionCode,
        display: true
    },
    {
        key: 'admin-settings',
        title: 'Impostazioni',
        icon: FaCogs,
        path: '/admin/impostazioni',
        component: AdminSettings,
        display: true
    },
    {
        key: 'production-code-list',
        title: 'Lista Turni/Produzione',
        icon: FaBusinessTime,
        path: '/production-code-list',
        component: ProductionCodeList,
        display: false
    },
    {
        key: 'export-production-code',
        title: 'Export Turni/Produzione',
        icon: FaBusinessTime,
        path: '/export-production-code',
        component: ExportProductionCode,
        display: false
    }
]

export const demoRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard,
        display: true
    },
    {
        key: 'superadmin-settings',
        title: 'Impostazioni superadmin',
        icon: FaCogs,
        path: '/superadmin/impostazioni',
        component: SuperadminSettings,
        display: true
    },
    {
        key: 'admin-settings',
        title: 'Impostazioni admin',
        icon: FaCogs,
        path: '/admin/impostazioni',
        component: AdminSettings,
        display: true
    }
]

export const completeProfileRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: VoidDashboard,
        display: true
    },
    {
        key: 'complete-profile-settings',
        title: 'Completa profilo',
        icon: FaCogs,
        path: '/completa-profilo',
        component: CompleteProfile,
        display: true
    }
]

export const devRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard,
        display: true
    },
    {
        key: 'logs',
        title: 'Log',
        icon: FaClipboardList,
        path: '/log',
        component: Logs,
        display: true
    }
]