import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button, Card, CardHeader, CircularProgress, Collapse, Grid, makeStyles, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hover: {
        "&:hover": {
            backgroundColor: 'lightgray'
        }
    }
}))

const ProductionCodeList = () => {
    //CONSTANTS

    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [productionCodeList, setProductionCodeList] = useState([]);
    const [count, setCount] = useState(0);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [reset, setReset] = useState(false);
    //////////////////////////////////////////
    const [code, setCode] = useState('');
    const [datetimeFrom, setDatetimeFrom] = useState('');
    const [datetimeTo, setDatetimeTo] = useState('');

    const handleChangeCode = (e) => {
        setCode(e.target.value);
    }

    const handleChangeDatetimeFrom = (e) => {
        setDatetimeFrom(e.target.value);
    }

    const handleChangeDatetimeTo = (e) => {
        setDatetimeTo(e.target.value);
    }

    const handleClickResetFilter = () => {
        setCode('');
        setDatetimeFrom('');
        setDatetimeTo('');
        setReset(true);
    }

    const handleClickFilter = () => {
        getData();
    }

    const getData = async () => {
        setLoading(true);
        let filters = {
            code: code,
            dateFrom: datetimeFrom,
            dateTo: datetimeTo,
            limit: limit,
            offset: offset
        }
        ctx.getProductionCodeByFilters(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setProductionCodeList(jsonRes.turniProduzioneDomainList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        getData()
            .then(() => {
                setReset(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    const columns = [
        {
            name: "codice",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Codice"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 15) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 12) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "dateFrom",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Data da"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "dateTo",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Data a"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "author",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Autore"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.username;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "timestamp",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Timestamp"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "id",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Azioni"}</div>,
            options: {
                filter: false,
                display: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    return '';
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    return (
        <div className={classes.container}>
            <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{'Lista Codici produzione'}</Typography>
            {
                isLoading ?
                    <div className={classes.overlay}>
                        <CircularProgress color={"primary"} />
                    </div>
                    :
                    <div>

                        <Grid container style={{ marginBottom: theme.spacing(2) }}>
                            <Grid item xs={12}>
                                <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                    <CardHeader
                                        title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant='h6' color='textSecondary'>
                                                {'Filtri'}
                                            </Typography>
                                            {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                        </div>}
                                        onClick={() => { setFiltersVisible(!filtersVisible) }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <Collapse in={filtersVisible}>
                                        <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                            <Grid
                                                item
                                                md={3}
                                                className={classes.fullWidth}
                                                style={{
                                                    paddingLeft: theme.spacing(1),
                                                    paddingRight: theme.spacing(1),
                                                }}
                                            >
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    id="codice"
                                                    label="Codice produzione"
                                                    name="codice"
                                                    value={code}
                                                    onChange={handleChangeCode}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                className={classes.fullWidth}
                                                style={{
                                                    paddingLeft: theme.spacing(1),
                                                    paddingRight: theme.spacing(1),
                                                }}
                                            >
                                                <TextField
                                                    id="datetime-from"
                                                    label="Da"
                                                    type="datetime-local"
                                                    value={datetimeFrom}
                                                    onChange={handleChangeDatetimeFrom}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                md={3}
                                                className={classes.fullWidth}
                                                style={{
                                                    paddingLeft: theme.spacing(1),
                                                    paddingRight: theme.spacing(1),
                                                }}
                                            >
                                                <TextField
                                                    id="datetime-to"
                                                    label="A"
                                                    type="datetime-local"
                                                    value={datetimeTo}
                                                    onChange={handleChangeDatetimeTo}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                            <Grid
                                                item
                                                md={12}
                                                className={classes.fullWidth}
                                                style={{
                                                    paddingLeft: theme.spacing(1),
                                                    paddingRight: theme.spacing(1),
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <Button
                                                    onClick={handleClickResetFilter}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress size="2em" color='primary' />
                                                    ) : (
                                                        <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                    )}
                                                </Button>
                                                <Button
                                                    onClick={handleClickFilter}
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginLeft: theme.spacing(1) }}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress size="2em" color='primary' />
                                                    ) : (
                                                        <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Card>
                            </Grid>
                        </Grid>
                        <MUIDataTable
                            data={productionCodeList}
                            columns={columns}
                            options={options}
                        />
                    </div>
            }

        </div>
    )
}

export default ProductionCodeList;