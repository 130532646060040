import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Logo from '../assets/images/logorefineair.png';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import CustomContext from '../context/CustomContext';
import { CircularProgress, Grid, Slide } from '@material-ui/core';
import { properties } from '../configs/properties';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://muvepla.com/">
                {'Muvepla'}
            </Link>
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(6),
    },
    submit: {
        margin: theme.spacing(8, 0, 2),
    },
    forgotPassword: {
        margin: theme.spacing(6, 0, 0)
    }
}));

export const Login = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const ctx = useContext(CustomContext);

    const classes = useStyles();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [loginIn, setLoginIn] = useState(true);

    const handleClickForgotPassword = () => {
        setUsernameError(false);
        setPasswordError(false);
        setUsername('');
        setPassword('');
        setLoginIn(false);
    }

    const handleClickBackToLogin = () => {
        setUsernameError(false);
        setPasswordError(false);
        setUsername('');
        setPassword('');
        setLoginIn(true);
    }

    const handleRecoverPassword = () => {
        if (username?.length > 0) {
            setLoading(true);
            ctx.recoverPassword(username)
                .then(res => {
                    if (res?.status === 200) {
                        return res.json();
                    } else {
                        if (res?.status === 401) {
                            enqueueSnackbar('Credenziali errate!', { variant: 'error' });
                            throw new Error();
                        } else {
                            enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                            throw new Error();
                        }
                    }
                })
                .then(
                    (result) => {
                        setLoading(false);
                        enqueueSnackbar('Operazione avvenuta con successo! Controllare email.', { variant: 'success', });
                    },
                    (error) => {
                        setLoading(false);
                    }
                )
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        } else {
            setUsernameError(true);
        }
    }

    const handleLogin = () => {
        if (username?.length > 0 && password?.length > 0) {
            setLoading(true);
            ctx.login(username, password)
                .then(res => {
                    if (res?.status === 200) {
                        return res.json();
                    } else {
                        if (res?.status === 401) {
                            enqueueSnackbar('Credenziali errate!', { variant: 'error' });
                            throw new Error();
                        } else {
                            enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                            throw new Error();
                        }
                    }
                })
                .then(
                    (result) => {
                        setLoading(false);
                        Cookies.set(properties.tokenKey, result?.jwtToken);
                        Cookies.set('refineair-user', result);
                        if (result?.primaryColor && result?.secondaryColor) {
                            props?.handleChangeTheme({
                                palette: {
                                    primary: {
                                        main: result?.primaryColor
                                    },
                                    secondary: {
                                        main: result?.secondaryColor
                                    }
                                }
                            })
                        }
                        enqueueSnackbar('Login avvenuto con successo!', { variant: 'success', });
                        window.location = '/';
                    },
                    (error) => {
                        setLoading(false);
                    }
                )
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        } else {
            if (username?.length === 0) {
                setUsernameError(true);
            }
            if (password?.length === 0) {
                setPasswordError(true);
            }
        }
    }

    useEffect(() => {
        if (Cookies.get(properties.tokenKey)) {
            history.push('/');
        }
    });

    const handleChangeUsername = (e) => {
        setUsernameError(false);
        setUsername(e.target.value);
    }

    const handleChangePassword = (e) => {
        setPasswordError(false);
        setPassword(e.target.value);
    }

    const handleSubmitKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Slide direction="right" in={loginIn} mountOnEnter unmountOnExit timeout={{ enter: 300 }}>
                <Card className={classes.paper} raised>
                    <img src={Logo} alt='logo' />
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={username}
                            onChange={handleChangeUsername}
                            error={usernameError}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={handleChangePassword}
                            error={passwordError}
                            onKeyPress={handleSubmitKeyPress}
                        />

                        <Grid container className={classes.forgotPassword}>
                            <Grid item xs>
                                <Link variant="body2" style={{ cursor: 'pointer' }} onClick={handleClickForgotPassword}>
                                    {'Hai dimenticato la password?'}
                                </Link>
                            </Grid>
                        </Grid>
                        <Button
                            onClick={handleLogin}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isLoading}
                        >
                            {isLoading ?
                                <CircularProgress style={{ color: 'white' }} size='2em' />
                                :
                                <span style={{ fontWeight: 'bold' }}>{'Accedi'}</span>
                            }
                        </Button>
                    </form>
                </Card>
            </Slide>
            <Slide direction="left" in={!loginIn} mountOnEnter unmountOnExit timeout={{ enter: 300 }}>
                <Card className={classes.paper} raised>
                    <img src={Logo} alt='logo' />
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={username}
                            onChange={handleChangeUsername}
                            error={usernameError}
                        />

                        <Grid container className={classes.forgotPassword}>
                            <Grid item xs>
                                <Link variant="body2" style={{ cursor: 'pointer' }} onClick={handleClickBackToLogin}>
                                    {'Torna al login'}
                                </Link>
                            </Grid>
                        </Grid>
                        <Button
                            onClick={handleRecoverPassword}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isLoading}
                        >
                            {isLoading ?
                                <CircularProgress style={{ color: 'white' }} size='2em' />
                                :
                                <span style={{ fontWeight: 'bold' }}>{'Recupera password'}</span>
                            }
                        </Button>
                    </form>
                </Card>
            </Slide>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}