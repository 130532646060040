import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../configs/PrivateRoute';
import { Login } from '../pages/Login';
import NotFound from '../pages/NotFound';
import CustomContext from '../context/CustomContext';

const AppRouter = (properties) => {
    const ctx = useContext(CustomContext);
    return (
        <Switch>
            {/* <Route exact path='/login' component={Login} /> */}
            <Route exact path='/login' render={(props) => (
                <Login {...props} handleChangeTheme={properties.handleChangeTheme} />
            )} />
            {
                ctx.getRoutesByRole().map(route => {
                    return <PrivateRoute {...route} handleChangeTheme={properties.handleChangeTheme} />
                })
            }
            <Route path='/404' component={NotFound} />
            <Redirect exact from='/' to='/login' />
            <Redirect from='*' to='/404' />
        </Switch>
    )
}

export default AppRouter;