import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button, Card, CardHeader, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, MenuItem, TextField, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import { FaChevronDown, FaChevronUp, FaPencilAlt } from 'react-icons/fa';
import Transition from '../components/Transition';


const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const Dimensions = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [userList, setUserList] = useState([]);
    const [count, setCount] = useState(0);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [accountTipologia, setAccountTipologia] = useState('');
    const [editUserDialogVisible, setEditUserDialogVisible] = useState(false);
    const [editDialogId, setEditDialogId] = useState(0);
    const [editDialogNome, setEditDialogNome] = useState('');
    const [editDialogNomeError, setEditDialogNomeError] = useState(false);
    const [editDialogCognome, setEditDialogCognome] = useState('');
    const [editDialogCognomeError, setEditDialogCognomeError] = useState(false);

    const handleClickEditUser = (row) => {
        let user = {
            id: row[5],
            nome: row[0],
            cognome: row[1]
        }

        setEditDialogId(user.id);
        setEditDialogNome(user.nome);
        setEditDialogCognome(user.cognome);
        setEditUserDialogVisible(true);
    }

    const handleChangeEditNome = (e) => {
        setEditDialogNome(e.target.value);
        setEditDialogNomeError(false);
    }

    const handleChangeEditCognome = (e) => {
        setEditDialogCognome(e.target.value);
        setEditDialogCognomeError(false);
    }

    const handleCloseEditUserDialog = () => {
        setEditDialogCognomeError(false);
        setEditDialogNomeError(false);
        setEditDialogNome('');
        setEditDialogCognome('');
        setEditUserDialogVisible(false);
    }

    const handleChangeAccountTipologia = (e) => {
        setAccountTipologia(e.target.value);
    }

    const handleChangeSearchText = (e) => {
        setSearchText(e.target.value);
    }

    const handleClickFilter = () => {
        getData();
    }

    const handleClickResetFilter = () => {
        setSearchText('');
        setAccountTipologia('');
        setOffset(0);
        getData(true);
    }

    const getTableTitle = () => {
        return 'Anagrafiche';
    }

    const validationEditDialog = () => {
        let result = true;

        if(!editDialogNome || editDialogNome?.length === 0) {
            setEditDialogNomeError(true);
            result = false;
        }

        if(!editDialogCognome || editDialogCognome?.length === 0) {
            setEditDialogCognomeError(true);
            result = false;
        }

        return result;
    }

    const handleClickSaveEditUser = () => {
        if(validationEditDialog()){
            setLoading(true);
            ctx.updateUserDimension({id: editDialogId, nome: editDialogNome, cognome: editDialogCognome})
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    enqueueSnackbar(properties.successMessage, { variant: 'success'});
                    handleCloseEditUserDialog();
                    getData();
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
        }
    }

    const getData = async (reset = false) => {
        setLoading(true);
        let filters = {
            limit: limit,
            offset: reset ? 0 : offset,
            searchText: reset ? '' : searchText,
            idAccountTipologia: reset ? '' : accountTipologia
        }
        ctx.superadminDimensions(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setUserList(jsonRes.users);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    const columns = [
        {
            name: "nome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Nome"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (!formatted) {
                        return <div style={{ color: 'lightgray', fontStyle: 'italic' }} className={classes.centered} >{'Nome non inserito'}</div>;
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "cognome",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Cognome"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (!formatted) {
                        return <div style={{ color: 'lightgray', fontStyle: 'italic' }} className={classes.centered} >{'Cognome non inserito'}</div>;
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "email",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Email"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (!formatted) {
                        return <div style={{ color: 'lightgray', fontStyle: 'italic' }} className={classes.centered} >{'Email non inserita'}</div>;
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "utenteInstallatore",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                {"Manutentore"}
            </div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (!formatted) return '';
                    return <div className={classes.centered} >{formatted?.nome + ' ' + formatted?.cognome}</div>;
                }
            }
        },
        {
            name: "attivo",
            options: {
                display: false
            }
        },
        {
            name: "id",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"User"}</div>,
            options: {
                filter: false,
                sort: false,
                /* display: false, */
                customBodyRender: (value, tableData, updatedValue) => {
                    return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {/*  rowData[4] is the property 'attivo'  */}
                        {tableData.rowData[4] && <FaPencilAlt style={{ cursor: 'pointer' }} onClick={() => {handleClickEditUser(tableData.rowData)}} />}
                    </div>
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    return (
        <div>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{getTableTitle()}</Typography>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>
                            <Grid container style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant='h6' color='textSecondary'>
                                                    {'Filtri'}
                                                </Typography>
                                                {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                    : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                            </div>}
                                            onClick={() => { setFiltersVisible(!filtersVisible) }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Collapse in={filtersVisible}>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    lg={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="searchText"
                                                        label="Nome/Cognome/Email"
                                                        name="searchText"
                                                        value={searchText}
                                                        onChange={handleChangeSearchText}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        id="tipo_utente"
                                                        select
                                                        label="Tipo di utente"
                                                        value={accountTipologia}
                                                        onChange={handleChangeAccountTipologia}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                    >
                                                        <MenuItem value=''>
                                                            <em>
                                                                {'Selezionare tipo utente'}
                                                            </em>
                                                        </MenuItem>
                                                        {[
                                                            { id: 2, nome: 'Manutentore' },
                                                            { id: 3, nome: 'Cliente' }
                                                        ].map((ta) => {
                                                            return (
                                                                <MenuItem key={ta.id} value={ta.id}>
                                                                    {ta.nome}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={handleClickResetFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={handleClickFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: theme.spacing(1) }}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            </Grid>
                            <MUIDataTable
                                data={userList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                }
                <Dialog
                    open={editUserDialogVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseEditUserDialog}
                    aria-labelledby="edit-user-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Modifica Utente`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="nome_edit"
                                    label="Nome"
                                    name="nome"
                                    value={editDialogNome}
                                    error={editDialogNomeError}
                                    onChange={handleChangeEditNome}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="cognome_edit"
                                    label="Cognome"
                                    name="cognome"
                                    value={editDialogCognome}
                                    error={editDialogCognomeError}
                                    onChange={handleChangeEditCognome}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseEditUserDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Chiudi"}</span>
                        </Button>
                        <Button
                            onClick={handleClickSaveEditUser}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>

    )
}

export default Dimensions;