import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CustomContext from '../context/CustomContext';
import { useSnackbar } from "notistack";
import { properties } from "../configs/properties";
import Transition from '../components/Transition';
import templateCsv from "../assets/templates/template_production_import.csv";
import { useHistory } from "react-router";

const useStyle = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(8),
    },
    fullWidth: {
        width: "100%",
    },
    downloadPdf: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
}));

const validFileTypes = [
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
]

const ImportProductionCode = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [datetimeFrom, setDatetimeFrom] = useState('');
    const [datetimeFromError, setDatetimeFromError] = useState(false);
    const [datetimeTo, setDatetimeTo] = useState('');
    const [datetimeToError, setDatetimeToError] = useState(false);
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [file, setFile] = useState('');
    const [importLoading, setImportLoading] = useState(false);

    const validateFile = (file) => {
        console.log(file?.type)
        return validFileTypes.includes(file?.type);
    }

    const handleChangeFile = (e) => {
        setFile(e.target.value);
        if (e.target.value && validateFile(e.target.files[0])) {
            setImportLoading(true);
            const formData = new FormData();
            formData.append(
                'file',
                e.target.files[0],
                e.target.files[0].name
            );
            ctx.uploadProductionCodeCsv(formData)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setImportLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setImportLoading(false);
                        setFile('');
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes) {
                        setImportLoading(false);
                        setFile('');
                        enqueueSnackbar('Upload avvenuto con successo.', { variant: 'success' })
                    }
                })
                .catch(e => {
                    setImportLoading(false);
                    setFile('');
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        } else {
            setFile('');
            enqueueSnackbar('File non valido.', { variant: 'warning' })
        }
    }

    const handleClickOpenImportDialog = () => {
        setImportDialogVisible(true);
    }

    const handleCloseImportDialog = () => {
        setImportDialogVisible(false);
        setFile('');
    }

    const validation = () => {
        let result = true;

        if (!code || code?.length === 0) {
            result = false;
            setCodeError(true);
        }

        if (!datetimeFrom || datetimeFrom?.length === 0) {
            result = false;
            setDatetimeFromError(true);
        } else {

        }

        if (!datetimeTo || datetimeTo?.length === 0) {
            result = false;
            setDatetimeToError(true);
        }

        if (result === true) {
            if (new Date(datetimeFrom) >= new Date(datetimeTo)) {
                result = false;
                setDatetimeFromError(true);
                setDatetimeToError(true);
                enqueueSnackbar('Controllare che le due date siano in ordine cronologico.', { variant: 'error' });
            }
        }

        return result;
    }

    const getModel = () => {
        return {
            codice: code,
            dateFrom: datetimeFrom,
            dateTo: datetimeTo
        }
    }

    const handleClickSave = () => {
        if (validation()) {
            setLoading(true);
            ctx.saveNewProductionCode(getModel())
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes === true) {
                        setLoading(false);
                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                        resetForm();
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    }
                })
        }
    }

    const resetForm = () => {
        setCode('');
        setDatetimeFrom('');
        setDatetimeTo('');
    }

    const handleChangeCode = (e) => {
        setCodeError(false);
        setCode(e.target.value);
    }

    const handleChangeDatetimeFrom = (e) => {
        setDatetimeFromError(false);
        if (datetimeTo) setDatetimeToError(false);
        setDatetimeFrom(e.target.value);
    }

    const handleChangeDatetimeTo = (e) => {
        setDatetimeToError(false);
        if (datetimeFrom) setDatetimeFromError(false);
        setDatetimeTo(e.target.value);
    }

    useEffect(() => {
        /* initEnums(); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <form className={classes.form} noValidate>
                <Typography variant="h4" color='textSecondary'>{"Codice Produzione - turni"}</Typography>
                <Grid
                    container
                    spacing={3}
                    justify="space-around"
                    className={classes.container}
                >
                    <Grid
                        spacing={3}
                        item
                        container
                        lg={12}
                        className={classes.fullWidth}
                    >
                        <Grid item lg={3} xs={12}>
                            <a style={{ width: "100%", textDecoration: "none" }} href={templateCsv} download="template_csv.csv">
                                <Button
                                    onClick={() => { }}
                                    style={{ width: '100%' }}
                                    variant="contained"
                                    color="primary"
                                >
                                    {'Template csv'}
                                </Button>
                            </a>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button
                                onClick={handleClickOpenImportDialog}
                                style={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                            >
                                {'Import csv'}
                            </Button>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button
                                onClick={() => { history.push("/production-code-list") }}
                                style={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                            >
                                {'Lista'}
                            </Button>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button
                                onClick={() => { history.push("/export-production-code") }}
                                style={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                            >
                                {'Export'}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} className={classes.fullWidth}>
                        <Card raised className={classes.card}>
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="code"
                                        label="Codice Produzione"
                                        name="code"
                                        value={code}
                                        onChange={handleChangeCode}
                                        error={codeError}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    item
                                    lg={12}
                                    className={classes.fullWidth}
                                >
                                    <Grid
                                        item
                                        lg={12}
                                        style={{
                                            paddingLeft: theme.spacing(1),
                                            paddingRight: theme.spacing(1),
                                            paddingTop: theme.spacing(2),
                                            color: 'gray'
                                        }}
                                        className={classes.fullWidth}
                                    >
                                        {'Turno'}
                                    </Grid>
                                    <Grid
                                        style={{
                                            paddingLeft: theme.spacing(1),
                                            paddingRight: theme.spacing(1),
                                        }}
                                        item
                                        lg={6}
                                        className={classes.fullWidth}
                                    >
                                        <TextField
                                            id="datetime-from"
                                            label="Da"
                                            type="datetime-local"
                                            value={datetimeFrom}
                                            error={datetimeFromError}
                                            onChange={handleChangeDatetimeFrom}
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        style={{
                                            paddingLeft: theme.spacing(1),
                                            paddingRight: theme.spacing(1),
                                        }}
                                        item
                                        lg={6}
                                        className={classes.fullWidth}
                                    >
                                        <TextField
                                            id="datetime-to"
                                            label="A"
                                            type="datetime-local"
                                            value={datetimeTo}
                                            error={datetimeToError}
                                            onChange={handleChangeDatetimeTo}
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Button
                    onClick={handleClickSave}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    style={{ marginTop: theme.spacing(2) }}
                >
                    {isLoading ? (
                        <CircularProgress size="2em" color='primary' />
                    ) : (
                        <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                    )}
                </Button>
            </form>
            <Dialog
                open={importDialogVisible}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseImportDialog}
                aria-labelledby="import-dialog"
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>{`Import CSV`}</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="file"
                                id="file"
                                value={file}
                                onChange={handleChangeFile}
                                type='file'
                                InputProps={{
                                    inputProps: {
                                        accept: ".csv"
                                    }
                                }}
                                disabled={importLoading}
                                style={{ marginTop: theme.spacing(1) }}
                            />
                            <Grid
                                style={{
                                    marginTop: theme.spacing(2),
                                    marginBottom: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    onClick={handleCloseImportDialog}
                                    variant="contained"
                                    disabled={isLoading}
                                >
                                    {'Annulla'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ImportProductionCode;