import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Avatar, Button, Card, CardHeader, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Slide, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import { FaChevronDown, FaChevronUp, FaFilePdf, FaSearch, FaTimes, FaUserCircle } from 'react-icons/fa';
import { Autocomplete } from '@material-ui/lab';


const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReportList = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const [offset, setOffset] = useState(0);
    const [reportList, setReportList] = useState([]);
    const [count, setCount] = useState(0);
    const [userLogged, setUserLogged] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [clienti, setClienti] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [reset, setReset] = useState(false);

    const handleChangeCliente = (e, cliente) => {
        setCliente(cliente);
    }

    const handleChangeDateFrom = (data) => {
        if (data) {
            let formatted = new Date(moment(data).format('MM-DD-yyyy'));
            setDateFrom(formatted);
        } else {
            setDateFrom(data)
        }
    }

    const handleChangeDateTo = (data) => {
        if (data) {
            let formatted = new Date(moment(data).format('MM-DD-yyyy'));
            setDateTo(formatted);
        } else {
            setDateTo(data)
        }
    }

    const handleClickResetFilter = () => {
        setDateFrom(null);
        setDateTo(null);
        setCliente(null);
        setReset(true);
    }

    const handleClickFilter = () => {
        getData();
    }

    const getUserLogged = async () => {
        ctx.getUserLogged()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setUserLogged(jsonRes);
                }
            })
            .catch(e => {
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const getTableTitle = () => {
        switch (userLogged?.accountDomain?.accountTipologiaDomain?.id) {
            case 2:
                return `Report clienti ${userLogged?.azienda}`;
            case 1:
            case 3:
            case 4:
            default:
                return `Lista report ${userLogged?.nome} ${userLogged?.cognome}`;
        }
    }

    const getData = async () => {
        setLoading(true);
        let filters = {
            dateFrom: dateFrom ? moment(dateFrom.setDate(dateFrom.getDate() - 1)).format('yyyy-MM-DD') + 'T00:00:00.000Z' : null,
            dateTo: dateTo ? moment(dateTo).format('yyyy-MM-DD') + 'T00:00:00.000Z' : null,
            limit: limit,
            offset: offset,
            idUtenteUtilizzatore: cliente?.id
        }
        ctx.getReportList(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setCount(jsonRes.count);
                    setReportList(jsonRes.reportList);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const getOriginalReportFile = (idReport) => {
        setLoading(true);
        ctx.getOriginalReportFile(idReport)
            .then(res => {
                if (res.status === 200) {
                    return res.blob();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(blob => {
                if (blob) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'original_report.pdf';
                    a.click();
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const getReportFile = (idReport) => {
        setLoading(true);
        ctx.getReportFile(idReport)
            .then(res => {
                if (res.status === 200) {
                    return res.blob();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(blob => {
                if (blob) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'report.pdf';
                    a.click();
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const getReportListEnums = () => {
        ctx.getReportListEnums()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    ctx.logout();
                } else {
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setClienti(jsonRes.clienti);
                }
            })
            .catch(e => {
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getUserLogged();
        getReportListEnums();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    useEffect(() => {
        getData()
            .then(() => {
                setReset(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    const columns = [
        {
            name: "numeroReport",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Numero report"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 15) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 12) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "data",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Data"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = moment(new Date(value)).format('DD/MM/yyyy');
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "utenteInstallatoreDomain",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Manutentore"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.azienda;
                    if (formatted.length > 15) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 12) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "utenteUtilizzatoreDomain",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Cliente"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome + ' ' + value?.cognome;
                    if (formatted.length > 15) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 12) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "puntoCampionamentoDomain",
            label: <Tooltip title="Punto di campionamento">
                <div className={classes.centered} style={{ fontWeight: 'bold' }}>
                    {"Punto di campion..."}
                </div>
            </Tooltip>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "tipoAmbienteDomain",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Tipo ambiente"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value?.nome;
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "pathFileOriginale",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Report originale"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    if (value) {
                        return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <FaFilePdf color={theme.palette.primary.main} size='30' style={{ cursor: 'pointer' }} onClick={() => { getOriginalReportFile(tableData.rowData[7]) }} />
                        </div>
                    } else {
                        return '';
                    }
                }
            }
        },
        {
            name: "id",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Report"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {

                    return <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <FaSearch color={theme.palette.primary.main} size='30' style={{ cursor: 'pointer' }} onClick={() => { setDialogOpen(true); setSelectedReport(tableData.rowData) }} />
                        <FaFilePdf color={theme.palette.primary.main} size='30' style={{ cursor: 'pointer' }} onClick={() => { getReportFile(value) }} />
                    </div>
                }
            }
        },
        {
            name: 'contaLieviti',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'contaLievitiDataInizio',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'contaLievitiDataFine',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'contaMicrorganismi',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'contaMicrorganismiDataInizio',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'contaMicrorganismiDataFine',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'controlloMicrobiologico',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'controlloMicrobiologicoDataInizio',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'controlloMicrobiologicoDataFine',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'eseguitoDa',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'drBiologo',
            label: '',
            options: {
                display: false
            }
        },
        {
            name: 'timestamp',
            label: '',
            options: {
                display: false
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: (Math.floor(offset / limit)),
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: limit,
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        setOffset(tableState.page * limit);
                        getData();
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    const handleCloseReportDialog = () => {
        setDialogOpen(false);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{getTableTitle()}</Typography>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>

                            <Grid container style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant='h6' color='textSecondary'>
                                                    {'Filtri'}
                                                </Typography>
                                                {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                    : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                            </div>}
                                            onClick={() => { setFiltersVisible(!filtersVisible) }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Collapse in={filtersVisible}>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <DatePicker
                                                        margin="normal"
                                                        id="data_da"
                                                        format="dd/MM/yyyy"
                                                        value={dateFrom}
                                                        onChange={handleChangeDateFrom}
                                                        helperText="Data da"
                                                        fullWidth
                                                        cancelLabel="ANNULLA"
                                                        clearLabel='SVUOTA'
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                        maxDate={dateTo}
                                                        clearable
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <DatePicker
                                                        margin="normal"
                                                        id="data_a"
                                                        format="dd/MM/yyyy"
                                                        value={dateTo}
                                                        onChange={handleChangeDateTo}
                                                        helperText="Data a"
                                                        fullWidth
                                                        cancelLabel="ANNULLA"
                                                        clearLabel='SVUOTA'
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                        minDate={dateFrom}
                                                        clearable
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    {clienti.length > 0 &&
                                                        <Autocomplete
                                                            id="clienti"
                                                            options={clienti}
                                                            value={cliente}
                                                            onChange={handleChangeCliente}
                                                            getOptionLabel={(option) => (option.nome + ' ' + option.cognome)}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    label="Cliente"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    margin='normal'
                                                                />
                                                            }
                                                            renderOption={(option) =>
                                                                <React.Fragment>
                                                                    <Avatar style={{ marginRight: '10px' }}>
                                                                        <FaUserCircle />
                                                                    </Avatar>
                                                                    {option.nome + ' ' + option.cognome}
                                                                </React.Fragment>
                                                            }
                                                            ListboxProps={{
                                                                style: {
                                                                    border: '1px solid rgba(0,0,0,0.2)',
                                                                    borderRadius: '3px'
                                                                }
                                                            }}
                                                        />
                                                    }
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={handleClickResetFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={handleClickFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: theme.spacing(1) }}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            </Grid>
                            <MUIDataTable
                                data={reportList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                }

                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseReportDialog}
                    aria-labelledby="report-dialog"
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <FaTimes onClick={handleCloseReportDialog} style={{ color: theme.palette.primary.main, cursor: 'pointer' }} />
                        </div>
                        {`Report ${selectedReport[0]} del ${moment(new Date(selectedReport[1])).format('DD/MM/yyyy')}`}
                    </DialogTitle>
                    {selectedReport.length > 1 && <DialogContent>
                        <Grid container >
                            <Grid item xs={8}>
                                <p>{`Azienda: ${selectedReport[2].azienda}`}</p>
                                <p>{`Manutentore: ${selectedReport[2].nome} ${selectedReport[2].cognome}`}</p>
                                <p>{`Cliente: ${selectedReport[3].nome} ${selectedReport[3].cognome}`}</p>
                                <p>{`Punto di campionamento: ${selectedReport[4].nome}`}</p>
                                <p>{`Tipo ambiente: ${selectedReport[5].nome}`}</p>
                                <p>{`Conta microrganismi: ${selectedReport[11]} dal ${moment(new Date(selectedReport[12])).format('DD/MM/yyyy')} al ${moment(new Date(selectedReport[13])).format('DD/MM/yyyy')}`}</p>
                                <p>{`Conta lieviti: ${selectedReport[8]} dal ${moment(new Date(selectedReport[9])).format('DD/MM/yyyy')} al ${moment(new Date(selectedReport[10])).format('DD/MM/yyyy')}`}</p>
                                <p>{`Controllo microbiologico: ${selectedReport[14]} dal ${moment(new Date(selectedReport[15])).format('DD/MM/yyyy')} al ${moment(new Date(selectedReport[16])).format('DD/MM/yyyy')}`}</p>
                                <p>{`Eseguito da: ${selectedReport[17] ? selectedReport[17] : ''}`}</p>
                                <p>{`Biologo/Tecnico prelevatore: ${selectedReport[18] ? selectedReport[18] : ''}`}</p>
                                <p>{`Ultima modifica: ${selectedReport[19]?.date ? selectedReport[19].date.day + '/' + selectedReport[19].date.month + '/' + selectedReport[19].date.year : ''}`}</p>
                            </Grid>
                        </Grid>
                    </DialogContent>}
                </Dialog>
            </div>
        </MuiPickersUtilsProvider>

    )
}

export default ReportList;