import React, { useContext, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Button, Card, CardHeader, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Slide, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { properties } from '../configs/properties';
import CustomFooter from '../components/CustomFooter';
import IntOnlyNumberFormatCustom from '../components/IntOnlyNumberFormatCustom';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceHistoryList = () => {
    //CONSTANTS
    const pageSize = 8;
    const order = 1;

    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [deviceList, setDeviceList] = useState([]);
    const count = 0;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deviceResponse, setDeviceResponse] = useState('');
    const [filtersVisible, setFiltersVisible] = useState(false);
    //////////////////////////////////////////
    const [topic, setTopic] = useState('');
    const [topicError, setTopicError] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [serialNumberError, setSerialNumberError] = useState(false);
    const [daysFrom, setDaysFrom] = useState(7);
    const [daysFromError, setDaysFromError] = useState(false);
    const [lastPage, setLastPage] = useState(null);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [direction, setDirection] = useState('next');

    const handleChangeDaysFrom = (e) => {
        let value = e.target.value ? e.target.value : 0;
        setDaysFrom(value >= 0 ? value : -value);
        setDaysFromError(false);
    }

    const handleChangeSerialNumber = (e) => {
        setSerialNumber(e.target.value);
        setSerialNumberError(false);
    }

    const handleChangeTopic = (e) => {
        setTopic(e.target.value);
        setTopicError(false);
    }

    const handleClickViewDevice = (payload) => {
        setDeviceResponse(payload);
        setDialogOpen(true);
    }

    const handleClickResetFilter = () => {
        setSerialNumber('');
        setSerialNumberError(false);
        setDaysFrom(7);
        setDaysFromError(false);
        setTopic('');
        setTopicError(false);
    }

    const validateFilter = () => {
        let result = true;

        if (!serialNumber || serialNumber?.length === 0) {
            result = false;
            setSerialNumberError(true);
        }

        if (!topic || topic?.length === 0) {
            result = false;
            setTopicError(true);
        }

        if(!daysFrom || daysFrom?.length <= 0) {
            result = false;
            setDaysFromError(true);
        }

        return result;
    }

    const handleClickFilter = () => {
        if (validateFilter()) {
            setDirection('next');
            getData(0);
        }
    }

    const getData = async (page) => {
        setLoading(true);
        ctx.getDeviceTopicResponse(topic, serialNumber, daysFrom, pageSize, (page + 1), order)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setDeviceList(jsonRes);
                    setPage(page)
                    setNextDisabled(jsonRes?.length < pageSize || jsonRes?.length === 0);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const columns = [
        {
            name: "deviceId",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Device Id"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 35) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 32) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "topic",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Topic"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 35) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 32) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "payload",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Payload"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if(tableData.rowData[1].includes('ORE_LAMPADA') || tableData.rowData[1].includes('ORE_VENTOLA')){
                        formatted = (Math.ceil((Number(formatted) * 10) / 60)).toString();
                    }
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "data",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Data"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "lat",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Lat"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "lng",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Lng"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    let formatted = value;
                    if (formatted.length > 25) {
                        return <Tooltip title={formatted}>
                            <div className={classes.centered} >{formatted.substring(0, 22) + '...'}</div>
                        </Tooltip>
                    }
                    return <div className={classes.centered} >{formatted}</div>;
                }
            }
        },
        {
            name: "payload",
            label: <div className={classes.centered} style={{ fontWeight: 'bold' }}>{"Azioni"}</div>,
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableData, updatedValue) => {
                    if(tableData.rowData[1].includes('ORE_LAMPADA') || tableData.rowData[1].includes('ORE_VENTOLA')){
                        value = (Math.ceil((Number(value) * 10) / 60)).toString();
                    }
                    return <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSearch color={theme.palette.primary.main} size='30' style={{ cursor: 'pointer' }} onClick={() => { handleClickViewDevice(value) }} />
                    </div>
                }
            }
        }
    ];

    const options = {
        serverSide: true,
        count: count,
        page: page,
        download: false,
        filter: false,
        print: false,
        search: false,
        sort: false,
        viewColumns: false,
        selectableRows: 'none',
        rowsPerPage: pageSize,
        rowsPerPageOptions: [],
        customFooter: (
            count,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage
        ) => {
            return <CustomFooter changePage={(direction, page) => { setDirection(direction); changePage(page); }} count={count} rowsPerPage={pageSize} page={page} nextDisabled={nextDisabled} />;
        },
        textLabels: {
            body: {
                noMatch: "Nessun risultato",
                toolTip: "Ordina",
                columnHeaderTooltip: column => `Ordina per ${column.label}`
            },
            pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
            }
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    if (!isLoading) {
                        if (direction === 'next') {
                            //going forward
                            if (!lastPage || lastPage !== page) {
                                if (deviceList?.length > 0) {
                                    getData(tableState.page);
                                } else {
                                    setPage(tableState.page);
                                    setLastPage(page);
                                    setNextDisabled(true);
                                }
                            }
                        } else {
                            //going back
                            getData(tableState.page);
                        }
                    }
                    break;
                default:
                    /* console.log(action);
                    console.log('default', tableState); */
                    break;
            }
        },
    };

    const handleCloseDeviceDialog = () => {
        setDialogOpen(false);
        setDeviceResponse('');
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{'Storico topic dispositivi'}</Typography>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>

                            <Grid container style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant='h6' color='textSecondary'>
                                                    {'Filtri'}
                                                </Typography>
                                                {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                    : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                            </div>}
                                            onClick={() => { setFiltersVisible(!filtersVisible) }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Collapse in={filtersVisible}>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="topic"
                                                        label="Topic"
                                                        name="topic"
                                                        value={topic}
                                                        onChange={handleChangeTopic}
                                                        error={topicError}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    {/* <TextField
                                                        id="daysFrom"
                                                        select
                                                        label="Giorni"
                                                        value={daysFrom}
                                                        onChange={handleChangeDaysFrom}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="normal"
                                                    >
                                                        <MenuItem value={7}>
                                                            {'7'}
                                                        </MenuItem>
                                                        <MenuItem value={14}>
                                                            {'14'}
                                                        </MenuItem>
                                                    </TextField> */}
                                                    <TextField
                                                        label="Giorni"
                                                        variant="outlined"
                                                        margin='normal'
                                                        fullWidth
                                                        value={daysFrom}
                                                        error={daysFromError}
                                                        onChange={handleChangeDaysFrom}
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            inputComponent: IntOnlyNumberFormatCustom,
                                                            autoComplete: 'off'
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        id="serialNumber"
                                                        label="Seriale"
                                                        name="serialNumber"
                                                        value={serialNumber}
                                                        onChange={handleChangeSerialNumber}
                                                        error={serialNumberError}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={12}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingTop: theme.spacing(2),
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={handleClickResetFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={handleClickFilter}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: theme.spacing(1) }}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                            <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Card>
                                </Grid>
                            </Grid>
                            <MUIDataTable
                                data={deviceList}
                                columns={columns}
                                options={options}
                            />
                        </div>
                }

                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseDeviceDialog}
                    aria-labelledby="device-dialog"
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>
                        {`Payload`}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12}>
                                {isLoading ?
                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                        <CircularProgress />
                                    </div>
                                    : <p>{deviceResponse}</p>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleCloseDeviceDialog}
                            variant="contained"
                            color="primary"
                        >
                            <span style={{ fontWeight: "bold" }}>{"Chiudi"}</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </MuiPickersUtilsProvider>

    )
}

export default DeviceHistoryList;